.main {
  padding: 68px 0px 0px 15%;
  background-color: #f5f6fa;
  transition: padding-left 0.2s;

  &--fullsize {
    padding-left: 1.4%;

    .columns-wrapper {
      width: 100%;
    }

    .notes__item-wrapper {
      width: 16.6%;
    }

    .goals {
      &__body--filled {
        width: 100% !important;
      }
      &__item {
        width: calc(25% - 18px);
      }
    }

    .day__notes .notes__item-wrapper {
      width: 25%;
    }
    .day__main--fullsize .notes__item-wrapper {
      width: 16.6%;
    }

    .day__main {
      .goals__item {
        width: calc(25% - 18px);
      }
      &--fullsize {
        .goals__item {
          // width: calc(20% - 18px);
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .main {
    &:not(.main--fullsize) {
      padding-left: 17%;
    }
  }
}
@media (max-width: 1370px) {
  .main {
    &:not(.main--fullsize) {
      padding-left: 19%;
    }
  }
}

@media (max-width: $media-sm) {
  body {
    overflow-y: auto;
  }
  #root {
    height: auto;
  }

  .main {
    padding: 0 !important;
    overflow-y: auto;
    min-height: calc(100vh - 68px);
  }
  .day {
    height: auto !important;
  }
}
