$black: #010101;
$gray: #485a63;
$light-gray: #99a8b1;
$primary: #69c262;
$primary-hover: #3db134;
$primary-active: #1b9360;
$primary-light: #e1f3e0;
$yellow: #ffc01e;
$pale: #f6f7fb;
$danger: #ff715d;
$danger-hover: #e3513d;

$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;
$media-xxl: 1400px;
