.task-comments {
  width: 100%;

  &__title {
    font-size: 18px;
    font-family: 'plex medium';
    margin-bottom: 10px;
    color: $gray;
  }

  &__add-input {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-input {
      width: 100%;
      padding-right: 35px;
    }
    .anticon-send {
      position: absolute;
      cursor: pointer;
      right: 8px;
      svg {
        width: 20px;
        height: auto;
        color: $light-gray;
        transition: color 0.2s;
      }
      &:hover {
        svg {
          color: $gray;
        }
      }
    }
  }

  &__input-wrapper {
    padding: 15px 0;
    border-bottom: 1px dashed $light-gray;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  &__edit-input {
    border: none;
    border-bottom: 1px solid $primary-active;
    border-radius: 0;
    resize: none;
    width: 100%;
    padding: 6px 32px 6px 12px;
    line-height: 1.3;
  }
  &__edit-suffix {
    position: absolute !important;
    right: 8px;
    cursor: pointer;
    path {
      transition: fill 0.2s;
    }
    &:hover {
      path {
        fill: $gray;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 8px 6px 13px;
    border-bottom: 1px dashed $light-gray;

    &__left {
      width: 100%;
      margin-right: 10px;
    }
    &__date {
      font-size: 12px;
      opacity: 0.8;
      margin-bottom: 2px;
      margin-top: 5px;
    }
    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      align-self: stretch;
    }
    &__edit-icon,
    &__remove-icon {
      cursor: pointer;
      path {
        transition: fill 0.2s;
      }
      &:hover {
        path {
          fill: $gray;
        }
      }
    }
    &__edit-icon {
      width: 13px;
    }
    &__remove-icon {
      width: 22px;
    }
  }
}
