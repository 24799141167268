.task-uploads {
  margin-bottom: -8px;
  &__title {
    font-family: 'plex medium';
    font-size: 18px;
    color: $gray;
    margin-bottom: 10px;
  }
  .ant-upload-picture-card-wrapper {
    display: initial;
  }

  &__modal {
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      path {
        transition: fill 0.2s;
      }
      &:hover {
        path {
          fill: $gray;
        }
      }
    }
    .ant-modal-body {
      text-align: center;
    }
  }
  &__modal-title {
    display: flex;
    align-items: center;
    svg {
      margin-left: 20px;
      width: 25px;
      height: auto;
    }
  }
  &__modal-no {
    font-family: 'plex medium';
    font-size: 17px;
  }
}

@media (max-width: $media-sm) {
  .task-uploads {
    width: 100%;
    .ant-upload-list {
      display: flex;
      flex-wrap: wrap;
    }
    .ant-upload-list-picture-card-container {
      width: calc(33.3% - 8px);
    }
    .ant-upload-select {
      width: calc(33.3% - 8px);
    }
  }
}
