.auth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;

  &__container {
    width: 340px;
  }
}

.auth-form {
  display: flex;
  flex-direction: column;
  &__title {
    font-size: 24px;
    font-family: 'plex medium';
    text-align: center;
    margin-bottom: 30px;
  }
  &__info {
    margin-bottom: 30px;
    text-align: center;
    font-size: 14px;
    width: 80%;
    align-self: center;
  }
  &__error {
    margin-bottom: 20px;
    color: red;
    text-align: center;
  }
  &__fields {
    margin-bottom: 40px;
    > div {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
  &__forgot {
    align-self: center;
    font-size: 12px;
    font-family: 'plex bold';
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: $primary;
    @include smooth-hover;
  }
  &__button {
    width: 100%;
    height: 40px;
    margin-bottom: 30px;
    font-size: 14px;
  }
  &__footer-text {
    font-size: 14px;
    text-align: center;
    a {
      font-family: 'plex medium';
      color: $primary;
      @include smooth-hover;
    }
  }

  &__check-icon,
  &__close-icon {
    align-self: center;
    margin-bottom: 30px;
    width: 100px;
    height: auto;
  }
  &__check-icon {
    path,
    circle {
      stroke: $primary;
    }
  }

  &--login {
    .auth-form__fields {
      margin-bottom: 30px;
    }
  }

  &--verify {
    .auth-form__error {
      margin-bottom: 30px;
      color: black;
    }
  }
}

@media (max-width: $media-sm) {
  .auth-layout {
    padding: 0 30px;
    height: 100vh;
  }
}
