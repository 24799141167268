.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#485a63, 0.5);
  overflow: scroll;
  height: calc(100vh + 20px);
  padding: 20px 0 40px;
  overscroll-behavior: contain;
  // -webkit-overflow-scrolling: touch;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    margin: auto;
    z-index: 571;
    width: 650px;
    border-radius: 15px;
    background-color: white;
    position: relative;
    padding: 50px 45px 40px;
    box-shadow: 0px 10px 50px 0px rgba(#213554, 0.15);
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 20px;
    cursor: pointer;
    path {
      transition: 0.2s;
    }
    &:hover {
      path {
        fill: #798891;
      }
    }
  }
}

.modal-transition {
  &-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  &-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.2s, transform 0.2s;
  }
  &-exit {
    opacity: 1;
    transform: translateY(0);
  }
  &-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.2s, transform 0.2s;
  }
}

@media (max-width: $media-sm) {
  .modal {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 100px;
    &__wrapper {
      width: 100%;
      padding: 25px 22px 22px;
    }
    &__close {
      top: 10px;
      right: 12px;
    }
  }
}
