.note
  position: relative
  flex: 0 0 calc(25% - 27px)
  width: calc(25% - 27px)
  margin: 0 35px 15px 0
  padding: 10px 13px 
  border: 1px solid $grey
  border-radius: 10px 
  display: flex
  justify-content: space-between
  +x
    flex: 0 0 calc(25% - 16px)
    width: calc(25% - 16px)
    margin: 0 8px 10px
    padding: 10px 15px 
  &__wrapper
    width: 100%
    display: flex
    align-items: flex-start
    justify-content: space-between
  &__date
    margin-bottom: 7px
    font-size: 8px
    color: #C2C2C2 
  &__text
    font-size: 14px
    width: 100%
    cursor: text
    display: flex
    align-items: center
    p
      margin-bottom: 0 !important
      padding: 5px 7px !important
    +x
      font-size: 12px
  &__textarea
    width: 100%
    overflow: hidden
    resize: none
    border-radius: 2px
    padding: 4px 6px !important
    background: #F5F5F5
    color: black
  &__delete
    margin-left: 5px  
    margin-top: 5px
  &_add
    display: flex
    align-items: center
    justify-content: center
  &_add &__textarea
    font-size: 14px
    color: black
  &__link
    font-size: 16px
    color: $green  
    transition: all .3s
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    &:hover
      color: lighten($green ,10) 
  &-4th
    margin-right: 0 !important
