.icon-arrow-left {
  width: 0.55em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 0.55em;
  height: 1em;
  fill: initial;
}
.icon-attach {
  width: 1.14em;
  height: 1em;
  fill: #d5d5d5;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-cloud-arrow-down {
  width: 1.22em;
  height: 1em;
  fill: initial;
}
.icon-delete {
  width: 0.91em;
  height: 1em;
  fill: initial;
}
.icon-doc {
  width: 0.74em;
  height: 1em;
  fill: initial;
}
.icon-edit {
  width: 1.12em;
  height: 1em;
  fill: initial;
}
.icon-link {
  width: 1.07em;
  height: 1em;
  fill: initial;
}
.icon-list {
  width: 1.39em;
  height: 1em;
  fill: initial;
}
.icon-notice {
  width: 0.9em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-settings {
  width: 1em;
  height: 1em;
  fill: initial;
}
