@use "sass:math"

.tasks
  position: relative
  // height: 529px
  border-top: 1px solid $grey
  border-radius: 7px 7px 0 0 
  // overflow: auto
  &_sm
    height: auto
    border: 1px solid $grey
    &:not(:last-child)
      margin-bottom: 15px
  // &__list
  //   overflow: auto
  //   &::-webkit-scrollbar
  //     display: none
  &__item
    padding: 0 20px
    background: rgba($green, .05)
    +x
      padding: 0 15px 
    +w
      padding: 0 10px 
    &.active
      background: rgba($green, .1) 
    &_transition
      // transition: color .5s ease
    &_add
      padding: 14px 20px 17px
      background: $white
      +x
        padding: 10px 15px 12px
      +w
        padding: 9px 10px 8px
    &_empty
      height: 49px
      background: $white
      +x
        height: 43px
      +w
        height: 38px
    &_done
      // background: $green 
    &_cansel
      background: #E8E8E8 
    &:not(:last-child)
      border-bottom: 1px solid $grey
  &__item_unactive
    opacity: 0.5
  &__item_transfered &__head span
    // color: $white
    text-decoration: underline  
  &__item_done &__head span
    // color: $white
    text-decoration: line-through
  &__item_done span
    // color: $white
    text-decoration: line-through
  &__item_cansel &__head span
    color: #ACACAC 
  &__item.active &__links
    display: flex
  &__head
    position: relative
    padding: 13px 0 12px 0
    font-size: 17px
    line-height: math.div(22, 17)
    &-name
      margin-right: 27px
    +r(1899)
      font-size: 16px
    +x
      font-size: 14px
    +w
      padding: 10px 35px 9px 0
  &__info
    padding-bottom: 5px
    border-bottom: 1px solid #D1E0CF 
  &__body
    padding-bottom: 12px
  &__links
    position: absolute
    top: 20px
    right: -6px
    align-items: center
    +r(1899)
      top: 20px
    +x
      top: 20px
  &__worked-pomidors
    position: absolute
    top: 10px
    right: 0
    display: flex
    align-items: center
    font-size: 12px
    p
      margin-bottom: 0px
      margin-right: 3px
  &__worked-pomidors &__pic
    width: 18px
  &__worked-pomidors &__indicator
    margin: 0 !important
  &__done
    position: absolute
    top: 16px
    right: -3px
    display: flex
    align-items: center
    +r(1899)
      top: 16px
    +x
      top: 12px
  &__counter
    margin-right: 4px
    padding-top: 3px
    +fb
    font-size: 10px
  &__item_done &__counter
    color: $white 
  &__item_cansel &__counter
    color: #D8D8D8
  &__preview
    font-size: 0
  &__icon
    width: 16px
  &__link
    font-size: 15px
    .icon
      fill: darken(#D5D5D5, 5)
      transition: all .3s 
    .icon-list
      font-size: 9px
    .icon-edit
      font-size: 11px
    &:hover .icon
      fill: $black
    &:not(:last-child)
      margin-right: 8px
  &__foot
    display: flex
    justify-content: space-between
    margin-top: 20px
    &-dummy
      width: 13.64px
  &__save
    padding: 5px 0 
    +fm
    font-size: 12px
    color: $green 
    transition: color .3s
    +w
      padding: 0
    &:hover
      color: lighten($green, 10) 
  &__date
    cursor: pointer
    padding: 5px 20px 3px
    border-radius: 7px 7px 0 0 
    background: darken(#E9E9E9, 5)
    font-size: 12px
    color: $black
    &_today
      color: #fff
      background: $green
    +x
      padding: 5px 15px 3px
    +w
      padding: 5px 10px 3px
  &__top
    display: flex
    align-items: center
    margin-bottom: 18px
    cursor: default
  &__text
    font-size: 10px
    color: #9A9A9A 
  &__time
    color: #D8D8D8
    +w
      display: block
  &__number
    margin: -7px 8px 0
    padding: 0
    border-bottom: 1px solid $green  
    max-width: 35px
    text-align: center
    background: transparent
    +fb
    font-size: 20px
    color: $green 
  &__control
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 105px
    height: 105px
    margin: 0 auto 12px
    border-radius: 50%
    background: rgba($green, .2)
    transition: all .3s 
    +w
      width: 90px
      height: 90px
    &:before
      content: ""
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      width: 73px
      height: 73px
      border-radius: 50% 
      background: $green
    &:hover, &_paused
      background: rgba($green, .4)

  &__play
    position: relative
    z-index: 2
    margin-bottom: 2px
    padding: 0 7px  
    +fb
    font-size: 12px
    line-height: 1.2
    color: $white 
  &__timer
    position: relative
    z-index: 2
    padding: 3px 4px 0 
    border-top: 1px solid rgba($white, .5)
    font-size: 7px
    color: rgba($white, .5)  
  &__indicators
    display: flex
    justify-content: center
    flex-wrap: wrap
  &__indicator
    font-size: 0
    margin: 0 5px
  &__pic
    width: 26px
  &__add
    font-size: 11px
    color: $green  
    transition: all .3s
    &:hover
      color: lighten($green ,10) 
  &__btn
    padding: 0 5px
    min-width: 67px
    height: 34px
    border-radius: 17px 
    font-size: 12px
    display: block
  &__delete
    display: block  
  &__tags  
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
  &__tag
    font-size: 12px  
    margin-right: 5px
    border-bottom: 1px solid $green
    padding: 0 3px
    // background: #fff
   
.day-tasks
  &__date
    display: flex
    align-items: center
    height: 90px
    padding-left: 50px
    border-bottom: 1px solid #D5D5D5
    color: $black
    font-size: 27px
    +fb
  &__item
    position: relative
    padding: 29px 50px 25px 50px
    background-color: #19f10a0d
    color: $black
    font-size: 20px
    border-bottom: 1px solid #D5D5D5
    &_active
      background-color: $green
      color: $white
      +fb
    &_transferred
      opacity: .5
  &__worked-pomidors
    position: absolute
    top: 35%
    right: 15px
    display: flex
    align-items: center
    font-size: 14px
    p
      margin-bottom: 0px
      margin-right: 3px
  &__worked-pomidors &__pic
    width: 26px

  &__btns
    margin-top: 40px
    margin-bottom: 40px
    text-align: center
  .add-button
    background-color: $green
    color: $white
    font-size: 20px
    padding: 15px 60px
    border-radius: 30px

// .task-form
//   &__head
//     display: flex
//     justify-content: space-between
//     // height: auto
//     // padding-right: 50px
//     // padding-left: 40px !important
//     // margin-top: 30px !important

//     .anticon
//       cursor: pointer
//       border-radius: 100%
//       padding: 10px
//       margin-right: 10px
//       background-color: rgba($green, 0.06)
//       color: rgba($green, 0.9)
//       font-size: 18px
//       align-self: flex-start
//       // margin-top: 25px !important
//     input
//       margin-left: 40px
//       width: 100%
//       font-family: 'Geometria-Bold', serif
//       font-size: 24px
//       line-height: 1.29167
//       color: #1f1f1f
    
//   &__body
//     padding: 20px 50px
//   &__name
//     color: black
//     font-size: 30px
//     margin-bottom: 0px
//     +fb
//   &__date
//     color: #C2C2C2
//     margin-top: 0px
//     margin-bottom: 0px
//   &__description
//     margin-top: 40px
//     font-size: 21px
//     color: $black
  
.holded-tasks
  &__item
    position: relative
    cursor: pointer
    display: block
    padding: 12px 0
    font-size: 14px
    color: $black 
    width: 100%
    text-align: left
    display: flex !important
    justify-content: space-between
    +w
      padding: 9px 0 7px 3px
    &:first-child
      border-top: 1px solid $grey
      +w
        padding-top: 13px
    &:not(:last-child)
      border-bottom: 1px solid rgba($grey, .7)
    p
      margin: 3px 0 3px 3px
  &__add
    color: $green
    p
      font-size: 16px !important
  &__input
    border: 1px solid $green
    color: $black
    width: 100%

.autosize-textarea
  width: 100%
  font-family: 'Geometria-Bold', serif
  font-size: 24px
  line-height: 1.29167
  color: #1f1f1f
  border: 0 !important
  resize: none

.daypage-delete-icon
  cursor: pointer
  border-radius: 100%
  padding: 10px
  margin-right: 10px
  background-color: rgba($green, 0.06)
  color: rgba($green, 0.9) !important
  font-size: 18px
  align-self: flex-start

.time-badge
  margin-top: 5px
  margin-bottom: 5px
  background-color: $green
  display: flex
  align-items: center
  justify-content: space-around
  max-width: 65px
  padding: 5px
  border-radius: 2px
  img
    height: 16px
    width: 16px
    margin-right: 5px
    transform: scale(1.05) !important
  & > span
    all: unset !important
    color: white
    font-size: 13px
