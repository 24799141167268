.note-view {
  &__date {
    width: 100%;
    display: flex;
    align-items: center;
    input {
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
    }
    svg {
      path {
        fill: $light-gray;
      }
    }
  }
  &__time {
    width: 100%;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
