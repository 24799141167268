.react-tabs
  &__tab-list
    margin-bottom: 20px
    border-bottom: 1px solid #ACACAC
  &__tab
    border: none
  &__tab--selected
    border: none
    border-bottom: 2px solid #1F1F1F
  
.settings
  &__row
    display: flex
    flex-direction: row
  &__row_justify-between
    justify-content: space-between
  &__row_error
    display: flex
    justify-content: center
    color: $red
  &__box
    flex: 1
    padding: 0px 10px
  &__box_right-border
    border-right: 1px solid $grey
  &__box_image
    flex: unset
    padding-right: 30px
    div
      width: 100%
      height: 100%
    .logo
      cursor: pointer
      border: 1px solid $black
      width: 120px
      height: 120px
      border-radius: 100%
      background-size: cover
      background-repeat: no-repeat
  .input-file
    display: none
  &__field_padding
    padding-bottom: 25px
  &__field_full
    height: 100%
  .floating-label
    height: 100%
    input, textarea
      height: 100%
      border-bottom: 1px solid $grey
    span
      color: #D5D5D5
      font-size: 13px
  &__save
    margin-top: 20px
    font-size: 18px
    color: #1F1F1F
  .field
    &_error
      border: none !important
      border-bottom: 1px solid $red !important
.update-password
  .settings__box
    padding: 0px 20px
    &:first-child
      padding-left: 10px
    &:last-child
      padding-right: 10px
  .btn
    font-size: 16px
    padding: 10px 32px
    height: unset
.notifications
  .settings__field_padding
    padding-bottom: 15px
  label
    span
      margin-left: 10px

.stats
  &__graph
    height: 70%
  &__numbers
    display: flex
    justify-content: space-around
    margin-top: 40px
  .numbers
    &__label
      color: #707070
      margin-bottom: 5px
      text-align: center
    &__data
      font-size: 16px
      color: $black
      span
        margin-right: 13px
    .bold
      color: #1f1f1f
      font-weight: bold

.invoicing
  button
    border: 'none !important'
    display: 'inline-block'
    text-align: 'center'
    padding: '7px 20px'
    color: '#fff'
    font-size: '16px'
    font-family: 'OpenSans, sans-serif'
    cursor: 'pointer'
    border-radius: '2px'
    background: 'rgb(122, 183, 43)'
    img
      margin-right: 7px !important
      vertical-align: middle !important
    span
      vertical-align: middle !important

.language-select
  margin: 0 0 20px 10px
  span
    font-size: 16px
    color: black
  select
    color: black !important
    margin-left: 10px
    width: 50px
    border-radius: 4px
    outline: 0px !important
    background: transparent
    font-size: 15px
    cursor: pointer
    border: 1px solid rgba(112, 112, 112, 0.3)
    color: rgb(112, 112, 112)
