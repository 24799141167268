.user-avatar {
  margin-top: 15px;
  box-shadow: 0px 6px 30px 0px rgba(195, 199, 220, 0.26);

  .ant-menu {
    padding: 6px 0;
    border-radius: 6px;
  }

  &__item {
    padding: 12px 18px !important;
    height: auto !important;
    line-height: 1 !important;
    margin: 0 !important;
    font-family: 'plex medium';
    font-size: 13px;
    text-transform: uppercase;
    color: $gray;
    &:hover {
      color: $gray;
      background-color: #ebeeef;
    }

    &--logout {
      color: $danger;
      &:hover {
        color: $danger;
      }
    }
  }
}
