@font-face {
  font-family: 'Plex regular';
  src: url('../../fonts/IBMPlexSans-Regular.woff2') format('woff2'),
    url('../../fonts/IBMPlexSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Plex medium';
  src: url('../../fonts/IBMPlexSans-Medium.woff2') format('woff2'),
    url('../../fonts/IBMPlexSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Plex semibold';
  src: url('../../fonts/IBMPlexSans-SemiBold.woff2') format('woff2'),
    url('../../fonts/IBMPlexSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Plex bold';
  src: url('../../fonts/IBMPlexSans-Bold.woff2') format('woff2'),
    url('../../fonts/IBMPlexSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
