.task-view {
  position: relative;
  &__flag {
    margin-right: 5px;
    overflow: hidden;
    width: 20px;
    height: 20px;
  }
  &__urgent-wrapper {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  &__close {
    position: absolute;
    top: -24px;
    right: -3px;
    cursor: pointer;
    path {
      transition: 0.2s;
    }
    &:hover {
      path {
        fill: #798891;
      }
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 20px;
    font-family: 'plex medium';
    margin-right: 20px;
    width: 100%;
    margin-left: -12px;
  }
  &__dropdown {
    .ant-dropdown-menu {
      border-radius: 6px;
    }
    .ant-dropdown-menu-item {
      color: $danger;
    }
  }

  &__description-wrapper {
    margin-left: -12px;
    margin-right: -12px;
  }
  &__three-dots {
    min-width: 20px;
    height: auto;
    cursor: pointer;
    path {
      transition: fill 0.2s;
    }
    &:hover {
      path {
        fill: $gray;
      }
    }
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  &__tomatoes,
  &__time {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'plex medium';
    margin-right: 12px;
    svg {
      width: 37px;
      // margin-right: 5px;
      transform: scale(0.8);
    }
  }
  &__tomatoes {
    svg {
      width: auto;
    }
  }
  // &__tags {
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   margin-right: 12px;
  //   span {
  //     font-size: 14px;
  //     font-family: 'plex medium';
  //     margin-right: 6px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }
  // }
  &__date {
    font-size: 14px;
    color: $light-gray;
    font-family: 'plex medium';
    margin-left: auto;
  }
  &__actions {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__play,
  &__finish {
    display: flex;
    align-items: center;
    svg {
      width: 37px;
      height: auto;
    }
  }
  &__play {
    svg {
      margin-right: 12px;
    }
  }
  &__finish {
    svg {
      margin-left: 12px;
    }
  }

  &__name {
    font-size: 20px;
    // margin-bottom: 20px;
    width: 100%;
    resize: none;
    line-height: 1.5715;
    padding: 6px 12px;
    border-radius: 6px;
    border-color: $primary-active;
    transition: border-color 0.3s;
    // @include custom-scroll;
    @include hidden-scroll;

    &--inactive {
      border-color: transparent !important;
    }
  }
  &__tomato {
    font-size: 16px;
    width: 100%;
    resize: none;
    line-height: 1.5715;
    padding: 2px 5px;
    border-radius: 4px;
    border-color: $primary-active;
    transition: border-color 0.3s;
    // @include custom-scroll;
    @include hidden-scroll;

    &--inactive {
      border-color: transparent !important;
    }
  }
  &__description {
    font-size: 15px;
    margin-bottom: 20px;
    width: 100%;
    resize: none;
    line-height: 1.5715;
    padding: 6px 12px;
    border-radius: 6px;
    border-color: $primary-active;
    transition: border-color 0.3s;
    &--inactive {
      border-color: transparent !important;
    }
    &::placeholder {
      color: $light-gray;
    }
  }
  &__duetime {
    padding-left: 6px;
    padding-right: 0px;
    width: 55px;

    &--inactive {
      border-color: transparent !important;
    }
  }
  &__datepicker {
    padding-left: 4px;
    padding-right: 0px;
    input {
      width: 90px;
      text-align: center;
    }
    &--inactive {
      border-color: transparent !important;
    }
  }
  &__tags {
    min-width: 40%;
  }
  &__tags-select {
    .react-select {
      &__control {
        min-height: unset;
        height: 37px;
        overflow: hidden;
      }
      &__value-container {
        height: 24px !important;
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
      }
      &__indicators {
        display: none;
      }
      &__multi-value {
        width: auto;
      }
    }
    &--inactive {
      .react-select {
        &__control {
          border-color: transparent !important;
          &:hover {
            border-color: transparent !important;
          }
        }
        &__multi-value__remove {
          display: none;
        }
      }
    }
  }

  &__mobile-tags {
    margin-left: -6px;
    margin-right: -6px;
    .react-select__value-container {
      padding: 0;
    }
  }

  &__checklist {
    margin-bottom: 30px;
  }

  &__uploads {
    margin-bottom: 25px;
  }

  &__history {
    margin-top: 25px;
    h1 {
      font-size: 18px;
      font-family: 'plex medium';
      font-weight: normal;
      color: $gray;
      margin-bottom: 10px;
    }
    &__item {
      margin-bottom: 6px;
    }
    &__item-text {
      font-size: 15px;
      display: flex;
      flex-wrap: wrap;
      line-height: 1.5;
      > * {
        margin-right: 5px;
      }
      strong {
        font-family: 'plex bold';
        font-weight: normal;
      }
    }
  }
}

@media (max-width: $media-sm) {
  .task-view {
    &__head {
      margin-bottom: 0;
    }
    &__name {
      font-size: 20px;
    }
    &__description {
      margin-bottom: 0px;
    }
    &__info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      > div {
        margin: 0;
      }
    }
    &__checklist {
      margin-bottom: 25px;
    }
    &__uploads {
      margin-bottom: 15px;
    }
    &__actions {
      margin-top: 30px;
    }
    &__play {
      font-size: 14px;
    }
    &__finish {
      font-size: 14px;
    }
  }
}
