.goal-form {
  &__title {
    margin-bottom: 15px;
  }
  &__info {
    font-size: 15px;
    text-align: center;
    margin-bottom: 40px;
  }
  &__monthly {
    display: flex;
    align-items: center;
    font-family: 'plex medium';
    & > * {
      margin-right: 10px;
    }
  }
  &__monthly-icon {
    cursor: pointer;
  }
  &__check-icon {
    path {
      stroke: #ccd4d8 !important;
    }
    circle {
      stroke: #ccd4d8 !important;
    }
  }

  &__check-wrapper {
    display: flex;
    /*   justify-content: flex-end; */ // if we have day/month swithing apply these styles
    justify-content: center;
    align-items: center;
    font-family: 'plex medium';
    margin-left: auto;
  }
  &__check-mark {
    cursor: pointer;
    margin-left: 10px;

    path,
    circle {
      transition: stroke 0.2s;
    }
    &:hover {
      path,
      circle {
        stroke: $primary;
      }
    }

    &--done,
    &--done:hover {
      circle {
        fill: $primary;
        stroke: $primary;
      }
      path {
        stroke: white;
      }
    }
  }
}

@media (max-width: $media-sm) {
  .goal-form {
    &__title {
      margin-bottom: 10px;
    }
    &__info {
      margin-bottom: 20px;
    }
    &__monthly-text {
      font-size: 13px;
    }
    &__check-wrapper {
      font-size: 13px;
    }
  }
}
