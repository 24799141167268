.settings-form {
  &__card {
    box-shadow: 0px 6px 30px 0px rgba(195, 199, 220, 0.2);
    overflow: hidden;
    border: 1px solid #cdd4d9;
    border-radius: 6px;
    background-color: white;
    position: relative;
  }
  &__card1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__show__archived {
    bottom: 20px;
    margin-top: 40px;
    position: static;
    max-width : 170px;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__title {
    font-size: 28px;
    font-family: 'plex medium';
  }

  &__row {
    padding: 20px 30px;
    &:not(:last-child) {
      border-bottom: 1px solid #cdd4d9;
    }
  }
  &__group {
    padding: 20px 30px;
    .settings-form__row {
      border: none;
      padding: 0;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  &__item-name {
    display: flex;
    align-items: center;
    p {
      font-family: 'plex medium';
      font-size: 19px;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      cursor: pointer;
      border-radius: 50%;
    }
  }
  &__input {
    display: none;
  }

  &__project {
    box-shadow: 0px 6px 20px 0px rgba(195, 199, 220, 0.3);
    border: 1px solid #cdd4d9;
    border-radius: 6px;
    padding: 16px 18px;
    cursor: pointer;
    transition: box-shadow 0.2s;
    &:hover {
      box-shadow: 0px 6px 20px 0px rgba(195, 199, 220, 0.8);
    }

    &__name {
      font-size: 19px;
      font-family: 'plex medium';
      margin-bottom: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &__color {
      font-size: 14px;
      font-family: 'plex medium';
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      text-transform: capitalize;
    }
    &__color-dot {
      margin-left: 6px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }
    &__buttons {
      .ant-btn {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: $media-sm) {
  .settings-form {
    &__item-name {
      margin-bottom: 15px;
      min-width: 100%;
    }
    &__item-input {
      min-width: 100%;
    }

    &--app-settings {
      .settings-form__item-name {
        margin-bottom: 0;
        min-width: auto;
      }
      .settings-form__item-input {
        min-width: auto;
      }
    }
  }
}
