 .day {
  height: calc(100vh - 68px);
  overflow: auto;
  @include custom-scroll(13px, 0px);

  &__wrapper {
    display: flex;
    width: 100%;
    position: relative;
  }

  &__schedule {
    margin-bottom: 45px;
  }

  &__goals {
    background-color: white;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0px 6px 30px 0px rgba(#c3c7dc, 0.26);

    .goals {
      &__body {
        padding: 10px;
        &--filled {
          width: 133%;
        }
      }
      &__item {
        // width: calc(33.3% - 18px);
        margin-left: 9px;
        margin-right: 9px;
        // height: 45px;
      }
      &__add-item {
        // height: 45px;
      }
    }
  }
  &__body {
    background-color: white;
    margin-bottom: 30px;
    padding: 40px;
    box-shadow: 0px 6px 30px 0px rgba(#c3c7dc, 0.26);
    border-radius: 15px;
    // min-height: 25%;
  }
  &__notes {
    .notes__item-wrapper {
      width: 33.3%;
    }
  }

  &__main {
    height: 100%;
    width: 63%;
    padding: 40px;
    transition: width 0.5s;

    &--fullsize {
      width: 100%;

      .goals__body--filled {
        width: 100%;
      }
      .goals__item {
        // width: calc(20% - 18px);
      }

      .day-body {
        // padding-bottom: 25%;
        // margin-bottom: 25%;
        min-height: 20vw;

        &__add {
          width: 48%;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }

      .notes__item-wrapper {
        width: 20%;
      }
    }
  }
  &__side {
    background-color: white;
    padding: 50px 45px 40px;
    // width: 37%;
    min-width: 37%;
    max-width: 37%;
    min-height: calc(100vh - 68px);
  }
}

.schedule {
  display: flex;
  // justify-content: space-between;

  &__body {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    cursor: pointer;
    border-radius: 6px;
    &:first-child {
      margin-right: 3.5vw;
    }
    &:last-child {
      margin-left: 3.5vw;
    }
    transition: background-color 0.2s;
    path {
      fill: $light-gray;
      transition: fill 0.2s;
    }
    &:hover {
      background-color: #e8e9ed;
      path {
        fill: $gray;
      }
    }
  }

  &__day {
    width: 50px;
    height: 69px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    // transition: background-color 0.2s, color 0.2s;
    &:hover {
      background-color: #e8e9ed;
    }
    &--today {
      .schedule__digit-day {
        color: $yellow;
      }
      &.schedule__day--active {
        &,
        &:hover {
          color: white;
          background-color: $yellow;
        }
      }
    }
    &--active {
      background-color: $primary;
      .schedule__week-day,
      .schedule__digit-day {
        color: white;
      }
      &:hover {
        background-color: $primary;
      }
    }
  }
  &__week-day {
    margin-bottom: 10px;
  }
  &__digit-day {
    font-size: 19px;
    font-family: 'plex medium';
  }
}

.day-body {
  position: relative;
  &__add {
    margin-top: 20px;
    margin-left: 15%;
    // width: 85%;
    button {
      width: 100%;
    }
  }
  &__notasks {
    display: none;
  }

  &--without-time {
    .day-body__add {
      margin-left: 0;
    }
    .day-task {
      &__wrapper {
        width: 100%;
      }
      &__time {
        width: 0%;
        padding: 0;
      }
    }
  }
}

.day-task {
  display: flex;
  cursor: default !important;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &--selected {
    .day-task__wrapper {
      &,
      &:hover {
        border-color: $primary-active;
      }
    }
  }
  &--transferred {
    opacity: 0.8;
  }
  &--done {
    .day-task__title {
      text-decoration: line-through;
    }
  }

  &__time {
    padding-right: 40px;
    width: 15%;
    font-size: 23px;
    font-family: 'plex medium';
    display: flex;
    justify-content: center;
    align-items: center;
    // transition: all 0.3s;
    // transition: width 0.3s, padding 0.3s;
  }

  &__wrapper {
    background-color: white;
    width: 85%;
    border: 1px solid #99a8b1;
    border-radius: 10px;
    padding: 20px 20px 20px 23px;
    position: relative;
    cursor: pointer;
    transition: border-color 0.2s;
    // transition: border-color 0.2s, width 0.3s;
    &:hover {
      border-color: $primary-hover;
    }
  }
  &__borders {
    position: absolute;
    top: 10%;
    left: -3px;
    display: flex;
    flex-direction: column;
    height: 80%;

    div {
      width: 5px;
      height: 100%;
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
  }
  &__left {
    width: 100%;
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // svg:first-child {
    //   margin-bottom: 10px;
    // }
  }
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  &__title {
    font-size: 20px;
    font-family: 'plex medium';
    margin-right: 20px;
    color: $gray;
  }
  &__three-dots {
    margin-top: 12px;
    align-self: flex-start;
    min-width: 20px;
    height: auto;
    cursor: pointer;
    // transform: scale(1.2);
    path {
      transition: fill 0.2s;
    }
    &:hover {
      path {
        fill: $gray;
      }
    }
  }
  &__description {
    font-size: 15px;
    margin-bottom: 20px;
  }
  &__foot {
    display: flex;
    align-items: center;
  }
  &__tomatoes {
    margin-left: 2px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    font-family: 'plex medium';
    font-size: 14px;
    svg {
      margin-right: 5px;
    }
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    span {
      font-size: 14px;
      font-family: 'plex medium';
      margin-right: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__play-button,
  &__stop-button {
    cursor: pointer;
    width: 37px;
    height: auto;
    margin-bottom: 10px;
  }
  &__play-button {
    circle {
      transition: 0.2s;
    }
    &:hover {
      circle {
        fill: #3db134;
        stroke: #3db134;
      }
    }
  }
  &__stop-button {
    circle {
      transition: 0.2s;
      fill: #1b9360;
      stroke: #1b9360;
    }
    &:hover {
      circle {
        fill: #3db134;
        stroke: #3db134;
      }
    }
  }
  &__check-mark {
    width: 37px;
    height: auto;
    cursor: pointer;
  }

  &__checklist {
    margin-top: 20px;
    padding: 0 2px;
  }
}

.side-transition {
  &-enter {
    opacity: 0;
    // width: 0%;
    min-width: 0%;
    max-width: 0%;
  }
  &-enter-active {
    opacity: 1;
    // width: 37%;
    // transition: width 0.3s ease-out, opacity 0.3s;
    min-width: 37%;
    max-width: 37%;
    transition: max-width 0.3s ease-out, min-width 0.3s ease-out, opacity 0.3s;
  }
  &-exit {
    opacity: 1;
    // width: 37%;
    min-width: 37%;
    max-width: 37%;
  }
  &-exit-active {
    opacity: 0;
    // width: 0%;
    // transition: width 0.3s ease-out;
    min-width: 0%;
    max-width: 0%;
    transition: max-width 0.3s ease-out, min-width 0.3s ease-out;
  }
}

@media (max-width: $media-sm) {
  .day {
    &__main {
      padding: 15px;
    }
    &__body {
      padding: 10px 15px;
    }
    &__schedule {
      margin-bottom: 20px;
    }
    &__goals {
      padding: 10px 20px 5px 12px;
    }

    &__goalnotes {
      margin: 20px 0;
      overflow-x: auto;
    }
    &__goalnotes-wrapper {
      padding: 0 20px;
      width: 100%;
      display: flex;
      > div:first-child {
        margin-right: 15px;
      }
    }
    &__goalnotes-dumbass {
      padding: 10px;
    }
  }

  .schedule {
    width: 100%;
    overflow-x: auto;
    &__arrow {
      display: none;
    }
    &__body {
      min-width: 140%;
    }
    &__digit-day {
      font-size: 20px;
    }
  }

  .day-body {
    // margin-bottom: 0 !important;
    min-height: auto !important;
    &__list {
      margin-bottom: 15px;
    }
    &__add {
      width: 100% !important;
      margin-bottom: 10px;
    }
    &__notasks {
      display: block;
      text-align: center;
      font-family: 'plex medium';
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .day-task {
    &:not(:last-child) {
      margin-bottom: 0;
    }
    &--active {
      .day-task__wrapper {
        background-color: #f7fcf7;
      }
    }
    &__time {
      display: none;
    }
    &__wrapper {
      border: none;
      border-bottom: 1px dashed $light-gray !important;
      border-radius: 0;
      padding: 16px 18px;
      width: 100%;
    }
    &__borders {
      height: 76%;
      top: 12%;
    }
    &__title {
      // font-family: 'plex regular';
      font-size: 18px;
    }
    &__main {
      flex-direction: column;
    }
    &__left {
      margin-right: 0;
      margin-bottom: 15px;
    }
    &__right {
      flex-direction: row;
      align-items: center;
      > svg {
        width: 35px;
      }
    }
    &__right-center {
      margin-left: 15px;
      margin-right: auto;
      display: flex;
      align-items: center;
    }
    &__tomatoes {
      margin-right: 15px;
    }
    &__small-time {
      display: flex;
      align-items: center;
      font-family: 'plex medium';
      font-size: 14px;
      svg {
        margin-right: 5px;
      }
    }
    &__play-button,
    &__stop-button {
      margin-bottom: 0;
    }
  }
}
