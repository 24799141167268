.emoji-picker {
  position: relative;
  width: 100%;
  .emoji-mart {
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 141414;
    box-shadow: 0px 5px 10px 0px rgba(#213554, 0.25);
  }
  .emoji-mart-scroll {
    @include custom-scroll;
  }
  .emoji-mart-anchor-selected {
    path {
      fill: $primary;
    }
  }
  .emoji-mart-anchor-bar {
    background-color: $primary !important;
  }
  .emoji-mart-emoji {
    span {
      cursor: pointer;
    }
  }

  &__input {
    cursor: pointer;
    width: 100%;
    background-color: white;
    border: 1px solid #ccd4d8;
    border-radius: 6px;
    transition: border-color 0.3s;
    display: flex;
    align-items: center;
    font-size: 15px;
    height: auto;
    padding: 6px 12px;
    line-height: 1.57;
    height: 37px;
    &:hover {
      border-color: #808080;
    }
    &--focused {
      border-color: $primary-active !important;

      path {
        fill: $gray;
      }
    }
  }
  &__input-value {
    color: $gray;
    margin-right: 20px;
  }
  &__input-placeholder {
    color: #99a8b1;
  }
  &__input-icon {
    transform: rotate(180deg) scale(0.8);
    position: absolute;
    right: 14px;
  }
}

@media (max-width: $media-sm) {
  .emoji-picker {
    .emoji-mart {
      width: 100% !important;
    }
    .emoji-mart-category-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
