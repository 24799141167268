.header {
  width: 100%;
  // width: calc(100% - 13px);
  // margin-right: 13px;
  height: 68px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 30px 0px rgba(195, 199, 220, 0.26);
  background-color: white;
  z-index: 560;

  &__logo {
    height: 100%;
    // width: 280px;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 25px;
      margin-right: 30px;
    }
  }
  &__progress {
    width: 100%;
    padding: 0 34px;
  }
  &__progress-text {
    display: flex;
    img {
      margin-right: 8px;
    }
    span {
      color: $black;
      font-family: 'plex medium';
      align-self: flex-end;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__main {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__links {
    height: 100%;
    display: flex;
    align-items: center;
    // margin-left: 180px;
  }
  &__link {
    color: #485a63;
    position: relative;
    font-size: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'plex medium';
    cursor: pointer;
    transition: color 0.2s;
    &:not(:last-child) {
      margin-right: 60px;
    }
    &:hover {
      color: $primary;
    }
    &--active {
      color: $primary;
      &::after {
        content: '';
        height: 3px;
        width: 110%;
        position: absolute;
        bottom: 0;
        left: -5%;
        background-color: $primary;
      }
    }
  }

  &__right-contents {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    // padding-right: 34px;

    > * {
      margin-right: 30px;
    }
  }

  &__search-input {
    border-radius: 6px !important;
    input {
      width: 100%;
      // width: 280px !important;
    }
    .ant-input-suffix {
      color: #99a8b1;
      font-size: 16px;
    }
    &:hover {
      border-color: $primary !important;
    }
    &.ant-input-affix-wrapper-focused {
      border-color: $primary;
      box-shadow: 0 0 0 2px rgba(105, 194, 98, 0.2) !important;
    }
  }
  &__search-wrapper {
    width: 100%;
  }
  &__search {
    position: relative;
  }
  .infinite-scroll-component__outerdiv {
    overflow: hidden;
  }
  .infinite-scroll-component {
    margin: 14px 0 !important;
  }
  &__search-items {
    margin-top: 10px;
    position: absolute;
    // width: 330px;
    width: 100%;
    max-height: 320px;
    overflow: auto;
    background-color: white;
    border: 1px solid #cecece;
    box-shadow: 0px 10px 50px 0px rgba(#213554, 0.15);
    border-radius: 15px !important;

    &::-webkit-scrollbar {
      width: 4px;
      &-thumb {
        background-color: #a6a6a6;
      }
      &-track {
        background-color: #eaeaea;
        margin-top: 6px;
        margin-bottom: 6px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
  &__search-item {
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.1s;
    &:hover {
      background-color: $primary-light;
    }
    display: flex;
    &__left {
      display: flex;
      flex-direction: column;
      width: 85%;
    }
    &__right {
      width: 15%;
      display: flex;
      align-items: center;
      font-family: 'plex medium';
      font-size: 14px;
      svg {
        transform: scale(0.8);
      }
    }
    &__name {
      margin-bottom: 6px;
      font-family: 'plex medium';
    }
    &__date {
      font-size: 11px;
    }
  }
  &__notifications {
    cursor: pointer;
    .ant-badge-dot {
      box-shadow: 0 0 0 1.4px white;
    }
  }
  &__avatar {
    cursor: pointer;
  }
  &__search-no-items {
    text-align: center;
    font-family: 'plex medium';
  }
}

.notifications {
  width: 300px;
  padding: 24px 20px;
  border-radius: 15px;
  background-color: white;
  z-index: 12;
  box-shadow: 0px 6px 30px 0px #c3c7dc1f;
  margin-top: 30px;
  margin-right: -30px;
  &::before {
    content: '';
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 20px solid white;
    position: absolute;
    top: 16px;
    right: 0px;
  }

  &__title {
    font-size: 20px;
    font-family: 'plex medium';
    color: $gray;
  }

  &__body {
    margin-top: 12px;
  }
  .ant-menu {
    border-right: none !important;
  }
  &__item {
    height: auto !important;
    padding: 8px 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #99a8b1;

    &:last-child {
      padding-bottom: 0 !important;
      border-bottom: none;
    }
    &::after {
      display: none;
    }
  }
  &__item-text {
    width: 90%;
    white-space: normal !important;
    line-height: 1.5 !important;
    font-size: 15px;
  }
  &__item-icon {
    width: 10%;
    margin-right: 10px;
    height: auto;
  }
  .ant-menu-item-active {
    color: $gray !important;
    opacity: 0.82;
  }
  .ant-menu-item-selected {
    color: $gray !important;
    background-color: white !important;
  }
}

.header-stats {
  display: flex;
  align-items: center;
  width: 30%;

  &__planning {
    margin-right: 30px;
    width: 100%;
    // width: 50%;
  }
  &__planning-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__planning-text {
    font-family: 'plex medium';
    font-size: 15px;
    margin-right: 10px;
  }
  &__planning-percentage {
    font-family: 'plex medium';
    font-size: 20px;
    color: $primary;
  }

  &__right-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: 'plex medium';
    color: $light-gray;
    white-space: nowrap;
    span {
      color: $primary;
      margin-left: 10px;
    }
  }
}

@media (max-width: 1600px) {
  .header__search-items {
    width: 120%;
  }
  .header__logo {
    width: 17%;
  }
}

@media (max-width: 1370px) {
  .header__logo {
    width: 19%;
  }
}
