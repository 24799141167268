*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  --scroll-bar: initial;
}

body {
  font-family: 'Plex regular' !important;
  color: $gray;
  overflow: hidden;
  // @include custom-scroll(13px, 0px);
  // min-width: calc(100vw);
  // overflow-x: hidden;
  // min-width: 120vw;
  // overflow-x: scroll !important;
}

#root {
  height: 100%;
  width: 100%;
}

.page-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-wrap {
  &::-webkit-scrollbar {
    display: none;
  }
}

.datepicker-calendar {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  .ant-picker-input {
    display: none;
  }
  &::after {
    content: url('../../icons/calendar.svg');
    cursor: pointer;
    height: 28px;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    background-color: #a4adb1;
    border-radius: 10px;
  }
  .ant-tooltip-arrow-content {
    background-color: #a4adb1;
    transform: translateY(-6.53553391px) rotate(45deg) scale(1.6);
  }
}

.ant-input {
  color: $gray !important;
  border-color: #ccd4d8;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 15px;
  resize: none;

  &:hover {
    border-color: #808080;
  }
  &:focus {
    box-shadow: none;
    border-color: $primary-active;
  }
  &::placeholder {
    color: #99a8b1;
  }
}

.ant-input-password {
  padding: 6px 12px;
  border-radius: 6px;
  cursor: text;

  &:hover {
    border-color: #808080;
  }
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: $primary-active;
  }
}

.ant-input-affix-wrapper {
  padding: 6px 12px;
  border-radius: 6px;
  cursor: text;
  &:hover {
    border-color: #808080;
  }
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: $primary-active;
  }
  svg {
    transform: scale(0.85);
  }
}

.ant-picker {
  padding: 6px 12px;
  border-color: #ccd4d8;
  border-radius: 6px;
  width: 100%;

  &:hover {
    border-color: #808080;
  }
  &.ant-picker-focused {
    box-shadow: none;
    border-color: $primary-active;
  }
  input {
    color: $gray !important;
    font-size: 15px;

    &::placeholder {
      color: #99a8b1;
    }
  }
  .ant-picker-suffix {
    position: relative;
    right: -2px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: $light-gray;
      }
    }
  }
  .ant-picker-clear {
    svg {
      width: 17px;
      height: 17px;
    }
  }
}

.ant-btn {
  border-radius: 6px;
  text-transform: uppercase;
  font-family: 'plex bold';
  font-size: 13px;
  color: $primary;
  height: 2.6em;
  // height: 34px;

  &:hover {
    color: $primary-hover;
    border-color: $primary-hover;
  }
  &.ant-btn-primary {
    color: white;
    border: none;
    background-color: $primary;
    &:hover {
      background-color: $primary-hover;
    }
  }
  &.ant-btn-dangerous {
    background-color: $danger;
    &:hover {
      background-color: $danger-hover;
    }
  }

  &[disabled] {
    color: white;
    background-color: $light-gray;
    &:hover {
      color: white;
      background-color: $light-gray;
    }
  }
}

.ant-switch-checked {
  background-color: $primary;
}

.quick-select-badge {
  background-color: #e1e5e8;
  font-family: 'plex medium';
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
  &:hover {
    background-color: darken(#e1e5e8, 6%);
  }
  &--active {
    background-color: #d2edd0;
    cursor: default;
    &:hover {
      background-color: #d2edd0;
    }
  }
}

.calendar-picker {
  z-index: 580;
  .ant-picker-panel-container {
    border-radius: 15px;
  }
  .ant-picker-header {
    padding: 8px 8px;
    border-bottom: none;
  }
  .ant-picker-header-view {
    font-size: 18px;
    color: $gray;
    font-family: 'plex medium';
    button {
      cursor: default;
      &:hover {
        color: inherit;
      }
    }
  }
  .ant-picker-date-panel {
    padding: 6px;
  }
  .ant-picker-date-panel,
  .ant-picker-body {
    width: auto;
    height: auto;
  }
  .ant-picker-content {
    border-collapse: separate;
    border-spacing: 8.5px;
  }
  thead {
    th {
      color: $light-gray;
    }
  }
  .ant-picker-cell {
    padding: 0;
    margin: 4px !important;
    &::before {
      display: none !important;
      border: none !important;
    }
  }
  .ant-picker-cell-in-view {
    .ant-picker-cell-inner {
      color: $gray;
    }
  }
  .ant-picker-cell-inner {
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px !important;
    height: 34px !important;
    font-family: 'plex medium';
    font-size: 17px;
    margin: 0 !important;

    &::before {
      border: none !important;
    }
  }
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      color: $primary;
      background-color: rgba(#69c262, 0.2) !important;
    }
  }
}

.time-picker {
  z-index: 580;
  .ant-picker-panel-container {
    border-radius: 15px;
  }

  .ant-picker-content {
    padding: 8px;
    border-radius: 15px;
  }
  .ant-picker-footer {
    border-top: none;
  }

  .ant-picker-time-panel-column {
    @include custom-scroll;
    // &:nth-child(3) {
    //   display: none;
    // }
  }

  .ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
      background-color: $primary-light !important;
    }
  }
  .ant-picker-time-panel-cell-inner {
    font-family: 'plex medium';
    font-size: 17px;
    color: $gray !important;
  }
  .ant-picker-ok {
    width: 100%;
    button {
      width: 100%;
      height: 26px;
    }
  }
}

.ant-modal-mask {
  z-index: 1000;
}
.ant-modal-wrap {
  z-index: 1000 !important;
}

.field-with-validation {
  width: 100%;
  position: relative;
  &--invalid {
    .ant-input {
      border-color: red;
    }
    .ant-input-password {
      border-color: red;
    }
    .ant-input-affix-wrapper {
      border-color: red !important;
    }
  }
  &__error-text {
    position: absolute;
    top: -18px;
    left: 0px;
    color: red;
    font-size: 13px;
    margin-left: 4px;
  }
}

.check-icon {
  cursor: pointer;

  path,
  circle {
    transition: stroke 0.2s;
  }
  &:hover {
    path,
    circle {
      stroke: $primary;
    }
  }

  &--done,
  &--done:hover {
    // cursor: default;
    circle {
      fill: $primary;
      stroke: $primary;
    }
    path {
      stroke: white;
    }
  }
}

.play-icon {
  cursor: pointer;
  circle {
    transition: 0.2s;
  }
  &:hover {
    circle {
      fill: #3db134;
      stroke: #3db134;
    }
  }
}

.stop-icon {
  cursor: pointer;
  circle {
    transition: 0.2s;
    fill: #1b9360;
    stroke: #1b9360;
  }
  &:hover {
    circle {
      fill: #3db134;
      stroke: #3db134;
    }
  }
}

.checkbox {
  font-size: 15px;
  margin-left: 0 !important;
  .ant-checkbox-inner {
    border-color: $light-gray;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: $primary;
    }
  }
  &.ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      border-color: $primary;
      background-color: $primary;
    }
  }
  .ant-checkbox {
    margin-right: 8px;
    transform: scale(1.5);
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary;
  }

  &__label {
    @include smooth-hover;
  }
}

.form-margin {
  margin-bottom: 35px;
}
.form-title {
  font-size: 26px;
  font-family: 'plex medium';
  color: $gray;
  text-align: center;
  margin-bottom: 40px;
}

.loading-mask {
  position: absolute;
  top: 0;
  right: 0;
  // background-color: rgba(white, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-transition {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 0.2s;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.page-not-found {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 120px;
  h1 {
    font-size: 124px;
    margin-bottom: 30px;
    font-family: 'plex medium';
  }
  p {
    font-size: 34px;
    margin-bottom: 60px;
  }
  button {
    background-color: $primary;
    color: white;
    padding: 10px 32px;
    border-radius: 6px;
    font-family: 'plex medium';
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.3s;
    &:hover {
      background-color: $primary-hover;
    }
  }
}

.ant-select {
  &.ant-select-focused {
    .ant-select-selector {
      border-color: $primary-active !important;
      box-shadow: none !important;
      &:hover {
        border-color: $primary-active !important;
      }
    }
    .ant-select-arrow {
      path {
        fill: $primary-active !important;
      }
    }
  }

  &:hover {
    .ant-select-arrow {
      path {
        fill: #808080;
      }
    }
  }

  .ant-select-selector {
    border-radius: 6px !important;
    height: 37px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    &:hover {
      border-color: #808080 !important;
    }
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    width: auto;
    svg {
      width: 11px;
      transform: rotate(180deg);
    }
    path {
      transition: fill 0.3s;
    }
  }
}
.ant-select-dropdown {
  border-radius: 6px !important;

  .ant-select-item {
    &:hover {
      background-color: lighten($primary, 30%);
    }
  }
  .ant-select-item-option-selected {
    background-color: $primary !important;
    color: white;
  }
}

.button-warn {
  background-color: $yellow !important;

  &:hover {
    background-color: #ffa900 !important;
  }
}

@media (max-width: $media-sm) {
  .form-margin {
    margin-bottom: 18px;
  }
  .form-button {
    // width: auto !important;

    width: 100% !important;
    padding-left: 30px;
    padding-right: 30px;
  }
  .form-title {
    margin-bottom: 20px;
    font-size: 23px;
  }
  .form-buttons-row {
    flex-wrap: nowrap;
  }

  .calendar-picker {
    .ant-picker-content {
      border-spacing: 4px;
    }
    .ant-picker-body {
      padding: 2px;
    }
    .ant-picker-cell-inner {
      font-size: 16px;
    }
    .ant-picker-header {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  .time-picker {
    .ant-picker-time-panel-column {
      overflow-y: auto;
      overscroll-behavior-y: contain;
    }
  }

  .ant-modal-confirm {
    padding-left: 15px;
    padding-right: 15px;
    .ant-modal-content {
      border-radius: 15px;
    }
    .ant-modal-confirm-title {
      font-size: 20px;
      text-align: center;
      font-family: 'plex medium';
    }
    .ant-modal-confirm-btns {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      button {
        width: 100%;
      }
    }
    .ant-modal-confirm-btns button + button {
      margin-left: 15px;
    }
  }
}
