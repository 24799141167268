.notes {
  position: relative;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 20px;
    font-family: 'plex medium';
    color: $gray;
  }
  &__add {
    font-family: 'plex bold';
    text-transform: uppercase;
    font-size: 13px;
    color: $primary;
    cursor: pointer;
    text-align: right;
  }

  &__body {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
  }
  &__item-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    width: 20%;
  }
  &__item {
    position: relative;
    box-shadow: 0px 6px 30px 0px rgba(#c3c7dc, 0.26);
    background-color: white;
    width: 100%;
    padding: 12px 16px;
    border-radius: 10px;
    display: flex;
    height: 64px;
    display: flex;
    cursor: pointer !important;
    justify-content: space-between;
    transition: width 0.2s;
  }
  &__item-name {
    margin-right: 10px;
    overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__item-dates {
    color: #a4adb1;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__item-done {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: $primary;
    padding: 0px 6px;

    svg {
      margin-bottom: -2px;
      width: 8px;
    }
    path {
      stroke-width: 2px;
      stroke: white;
      fill: white;
    }
  }
}

@media (max-width: $media-sm) {
  .notes {
    &__body {
      margin-left: -6px;
      margin-right: -6px;
    }
    &__item-wrapper {
      width: 50% !important;
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 12px;
    }
    &__item {
      // padding: 12px 12px;
      padding: 10px 14px;
    }
    &__item-name {
      margin-right: 6px;
    }
    &__item-dates {
      font-size: 11px;
    }
  }
}
