.mobile-header {
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  &__unfold {
    margin-right: 15px;
    svg {
      width: 20px;
      height: auto;
    }
  }
  &__logo {
    width: 75%;
    display: block;
    margin: auto;
  }

  .header-stats {
    width: 100%;
    &__planning {
      margin-right: 15px;
    }
    &__planning-text {
      font-size: 16px;
    }
    &__planning-percentage {
      font-size: 20px;
    }
  }
}
