.week {
  height: calc(100vh - 68px);
  overflow: auto;
  @include custom-scroll(13px, 0px);

  &__head {
    padding: 15px 38px 10px 25px;
    // padding: 31px 38px 18px;
    background-color: white;
    display: flex;
    align-items: center;
  }
  &__navigation {
    width: 25%;
    transition: width 0.2s;
  }
  &__tasks {
    position: relative;
    // padding-top: 15px;
    // margin-top: 15px;
  }
  &__goals {
    margin-left: 40px;
    width: 100%;
    max-width:75%;
  }
  &__notes {
    margin-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 35px;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__dates {
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-top: 4px;
  }
  &__arrow {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    &:first-child {
      margin-right: 14px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  &__current-week {
    font-family: 'Plex medium';
    font-size: 22px;
    color: $gray;
    white-space: nowrap;
  }
  &__calendar {
    margin-left: 10px;
    display: flex;
  }

  &__today {
    text-align: center;
    font-size: 20px;
    color: $primary;
    font-family: 'plex medium';
    cursor: pointer;
    @include smooth-hover;
  }

  &__planning {
    margin-top: 35px;
  }
  &__planning-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__planning-text {
    font-family: 'plex medium';
    font-size: 16px;
  }
  &__planning-percentage {
    font-family: 'plex medium';
    font-size: 20px;
    color: $primary;
  }
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: $primary !important;
  }
}
.ant-picker-cell-today {
  .ant-picker-cell-inner::before {
    border-color: $primary !important;
  }
}

.column-scroll-wrapper {
  overflow: hidden;

  &__goto-left,
  &__goto-right {
    z-index: 8;
    position: absolute;
    cursor: pointer;
    transform: scale(1.1);
    rect {
      transition: fill 0.2s;
    }
    &:hover rect {
      fill: #64737c;
    }
  }
  &__goto-left {
    top: 40%;
    left: 1%;
  }
  &__goto-right {
    top: 40%;
    right: 1.2%;
  }

  &__slider-dots {
    position: absolute;
    bottom: 0;
    right: 49%;
    display: flex;
  }
  &__slider-dots-item {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #9aa8b1;
    cursor: pointer;
    &:hover {
      background-color: #8b9aa3;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
    &--active {
      background-color: $primary;
      cursor: default;
      &:hover {
        background-color: $primary;
      }
    }
  }
}
.columns-wrapper {
  width: 120%;
  display: flex;
  padding-top: 25px;
  padding-left: 2.4%;
  padding-right: 2.4%;
  margin-bottom: 50px;
  transition: width 0.2s;

  &__weekends {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 28vw;
  }
}
.column {
  width: 20%;
  background-color: white;
  border-radius: 15px;
  height: 70vh;
  overflow: hidden;
  // overflow: auto;
  position: relative;
  box-shadow: 0px 6px 30px 0px #c3c7dc1a;
  transition: box-shadow 0.2s, background-color 0.2s;

  &--weekend {
    width: 100%;
    height: 50%;
    &:first-child {
      margin-bottom: 9%;
    }

    .column__row {
      height: 19%;
      // height: 25.7%;
    }
  }

  &:not(:last-child) {
    margin-right: 1.5%;
  }
  &:nth-child(5) {
    margin-right: 2%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &--while-task-dragged {
    .column__row {
      &::before,
      &:after,
      &-text {
        opacity: 0;
      }
    }
  }
  &--drop-enabled {
    box-shadow: 0px 0px 10px 0px #a7ffa0;

    &:hover {
      background-color: lighten($primary-light, 6%);
    }
    .column-task {
      &:hover {
        cursor: grabbing !important;
      }
    }
  }
  &--overflowed {
    .column__body {
      overflow: scroll;
      @include hidden-scroll;
    }
  }

  &__title {
    padding: 12px 14px 16px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    text-transform: capitalize;
    span {
      cursor: pointer;
      font-family: 'plex medium';
    }
    &--active {
      color: $primary;
    }
  }

  &__body {
    height: calc(100% - 48px);
    // height: 100%;
    overflow: hidden;
  }

  &__row {
    position: relative;
    width: 100%;
    // height: 34px;
    height: 8%;
    // height: 11%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 6%;
      height: 1px;
      width: 88%;
      background-color: #cdd4d9;
      transition: opacity 0.2s;
    }
    &:last-child {
      margin-bottom: 10px;
      &::after {
        content: '';
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 6%;
        width: 88%;
        background-color: #cdd4d9;
        transition: opacity 0.2s;
      }
    }
  }
  &__row-text {
    font-size: 13px;
    color: $primary;
    font-family: 'plex bold';
    cursor: pointer;
    text-transform: uppercase;
    transition: opacity 0.2s, color 0.2s;

    &:hover {
      color: $primary-hover;
    }
  }
}
.column-task {
  padding: 8px 20px;
  position: relative;
  &:last-child {
    margin-bottom: 10px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 6%;
    height: 1px;
    width: 88%;
    background-color: #cdd4d9;
  }
  &::after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 6%;
    width: 88%;
    background-color: #cdd4d9;
  }
  &:hover {
    cursor: auto !important;
  }
  &--transferred {
    .column-task__head {
      color: #485a6380 !important;
    }
  }
  // &--done {
  //   .column-task__title {
  //     text-decoration: line-through;
  //   }
  // }
  &--opened {
    padding: 12px 20px;
  }

  &__color-borders {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    div {
      width: 3px;
      height: 100%;
    }
  }

  &__urgent-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      cursor: pointer !important;
    }
  }
  &__title {
    margin-right: 14px;
    overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__three-dots {
    align-self: flex-start;
    margin-top: 3px;
    height: 16px;
    min-width: 19px;
    path {
      transition: fill 0.3s;
    }
    &:hover {
      path {
        fill: $gray;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }
  &__time {
    margin-top: 12px;
    margin-right: auto;
    display: flex;
    align-items: center;
    font-family: 'plex medium';
    svg {
      margin-right: 5px;
    }
  }
  &__tomatoes {
    margin-top: 12px;
    margin-left: auto;
    display: flex;
    align-items: center;
    font-family: 'plex medium';
    svg {
      margin-right: 5px;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    // margin-top: 12px;
    span {
      color: $danger;
      font-size: 13px;
      font-family: 'plex medium';
      margin-right: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:first-child {
        margin-top: 12px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
  }
  &__play-button,
  &__stop-button {
    cursor: pointer;
    width: 40px;
    height: auto;
  }
  &__play-button {
    circle {
      transition: 0.2s;
    }
    &:hover {
      circle {
        fill: #3db134;
        stroke: #3db134;
      }
    }
  }
  &__stop-button {
    circle {
      transition: 0.2s;
      fill: #1b9360;
      stroke: #1b9360;
    }
    &:hover {
      circle {
        fill: #3db134;
        stroke: #3db134;
      }
    }
  }
  &__check-mark {
    width: 40px;
    height: auto;
    cursor: pointer;

    path,
    circle {
      transition: stroke 0.2s;
    }
    &:hover {
      path,
      circle {
        stroke: $primary;
      }
    }

    &--done,
    &--done:hover {
      circle {
        fill: $primary;
        stroke: $primary;
      }
      path {
        stroke: white;
      }
    }
  }

  &__folded-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__folded-left {
    display: flex;
    align-items: center;
    svg {
      padding-top: 1px;
      margin-top: 5px;
      margin-right: 5px;
    }
  }
  &__folded-right {
    margin-top: 5px;
    padding: 2px 7px;
    background-color: $primary;
    color: white;
    font-size: 13px;
    border-radius: 4px;
    font-family: 'plex medium';
  }
  &__folded-description {
    width: 15px;
    height: auto;
  }
  &__folded-uploads {
    width: 14px;
    height: auto;
  }
  &__folded-comments {
    width: 16px;
    height: auto;
  }
  &__folded-clock {
    width: 17px;
    height: auto;
  }
  &__folded-duetime {
    font-size: 13px;
    font-family: 'plex medium';
    margin-top: 6px;
    margin-right: 5px;
  }
}

@media (max-width: 1370px) {
  .columns-wrapper {
    width: 150%;
    // width: 175%;
    padding-left: 2.8%;
    padding-right: 2.8%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: $media-sm) {
  .week {
    &__head {
      flex-direction: column;
      padding: 20px;
    }
    &__goals {
      margin-left: 0;
    }
    &__navigation {
      margin-bottom: 25px;
    }
  }
}
