.task-form {
  &__title {
    font-size: 26px;
    font-family: 'plex medium';
    color: $gray;
    text-align: center;
    margin-bottom: 40px;
  }

  &__minutes {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-family: 'plex medium';
    color: $light-gray;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  &__head-delete,
  &__head-clear {
    text-transform: uppercase;
    font-family: 'plex bold';
    font-size: 13px;
    cursor: pointer;
    transition: color 0.2s;
  }
  &__head-delete {
    color: $danger;
    &:hover {
      color: $danger-hover;
    }
  }
  &__head-clear {
    color: $primary;
    &:hover {
      color: $primary-hover;
    }
  }

  &--daypage {
    .task-form {
      &__title {
        margin-bottom: 0;
        font-size: 20px;
      }
      .form-buttons-row {
        flex-wrap: nowrap;
      }
    }
    .form-button {
      width: 100%;
    }
  }
}

.checklist {
  width: 100%;

  &__head {
    margin-bottom: 16px;
  }
  &__input {
    margin-bottom: 20px;
  }
  &__name {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: $light-gray;
    span {
      margin-right: 10px;
    }
  }

  .ant-progress-outer {
    padding-right: 0;
  }
  .ant-progress-text {
    font-family: 'plex medium';
    font-size: 16px;
    position: absolute;
    top: -14px;
    right: 0px;
  }

  &__add {
    cursor: pointer;
    font-size: 13px;
    color: $primary;
    text-transform: uppercase;
    font-family: 'plex bold';
    display: inline;
  }

  &__list {
    padding: 0 6px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__item-label {
    @include smooth-hover;
  }
  &__item-checkbox {
    font-size: 15px;
    .ant-checkbox-inner {
      border-color: $light-gray;
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: $primary;
      }
    }
    &.ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        border-color: $primary;
        background-color: $primary;
      }
    }
    .ant-checkbox {
      margin-right: 8px;
      transform: scale(1.5);
    }
    .ant-checkbox-checked::after {
      border: none;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $primary;
    }
  }
  &__item-remove {
    cursor: pointer;
    path {
      transition: 0.2s;
    }
    &:hover {
      path {
        fill: #798891;
      }
    }
  }
}

.form-button {
  min-width: 140px;
}

@media (max-width: $media-sm) {
  .task-form {
    &__minutes {
      font-size: 14px;
    }
  }
  .checklist {
    &__name {
      margin-bottom: 0;
    }
    &__head {
      margin-bottom: 10px;
    }
    &__item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &__input {
      margin-bottom: 15px;
    }
  }
}
