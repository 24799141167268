$blue: #74A7B8

.react-calendar
  &__navigation
    color: $blue
  &__month-view
    &__weekdays
      color: $blue
      abbr
        text-decoration: none !important
    &__days
      &__day
        color: rgba(0, 0, 0, 0.65)
      &__day--weekend
        color: inherit
      &__day--neighboringMonth
        color: #CDCDCD
  &__tile--active
    position: relative
    background-color: white
    &:enabled:hover,
    &:enabled:focus
      background-color: white
    &:after
      content: ''
      position: absolute
      top: 20%
      left: 20%
      display: block
      width: 60%
      height: 60%
      border: 1px solid $blue
      border-radius: 100%