@use "sass:math"


=x
  @media only screen and (max-width: "1699px")
    @content
=w
  @media only screen and (max-width: "1599px")
    @content
=d
  @media only screen and (max-width: "1120px")
    @content
=t
  @media only screen and (max-width: "1023px")
    @content
=m
  @media only screen and (max-width: "767px")
    @content
=s
  @media only screen and (max-width: "474px")
    @content

+font(geometria, geometria)
=f
  font-family: 'Geometria', serif

+font(geometria-medium, geometria-medium)
=fm
  font-family: 'Geometria-Medium', serif
  
+font(geometria-bold, geometria-bold)
=fb
  font-family: 'Geometria-Bold', serif

+font(geometria-extrabold, geometria-extrabold)
=feb
  font-family: 'Geometria-ExtraBold', serif

$black: #707070
$white: #fff
$green: #69C262
$red: #E24747
$grey: #E9E9E9

body
  min-width: 320px
  +f
  font-size: 16px
  line-height: 1.3
  // color: $black 
  &.no-scroll
    overflow: hidden


button
  background: none 
  
a
  text-decoration: none

.center
  max-width: 1200px
  margin: 0 auto
  padding: 0 30px

.hidden 
 display : none
 
.green-color
  color: $green !important

.btn
  min-width: 191px
  height: 34px
  border-radius: 4px 
  +fm
  font-size: 10px
  transition: all .3s
  &_green
    background: $green 
    color: $white
    &:hover
      background: lighten($green, 10) 
  &_small
    height: 30px !important
  &_white
    background: $white
    color: $green  
  &_border-green
    border: 1px solid $green 
    color: $green 
    &:hover
      background: $green
      color: $white

a.btn
  display: inline-flex
  justify-content: center
  align-items: center

.section
  display: flex
  min-width: 1280px

.aside
  flex: 0 0 332px
  width: 332px
  min-height: 100vh
  background: $white
  box-shadow: 0 0 50px rgba(#000, .07)
  +w
    flex: 0 0 260px
    width: 260px
  &__head
    display: flex
    justify-content: center
    align-items: center
    height: 87px
    padding: 10px
    border-bottom: 1px solid rgba($black, .3) 
    +w
      height: 65px
  &__logo
    +fm
    font-size: 24px
    color: rgba($black, .5)
    transform: scale(0.45, 0.45)
  &__user
    display: flex
    align-items: center
    padding: 0 !important

.user
  position: relative
  padding: 30px 10px 17px
  &__top
    display: flex
    justify-content: space-around
    align-items: flex-start
  &__preview
    position: relative
    z-index: 13
    flex: 0 0 110px
    width: 110px
    height: 110px
    margin-bottom: 15px
    border-radius: 50%
    font-size: 0
    overflow: hidden
  &__ava
    width: 100%
    min-height: 100% 
  &__preview &__btn
    display: none
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    min-width: 60px
    height: 20px
  &__settings
    margin: 0 9px
  &__control
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 31px
    height: 31px 
    border-radius: 50% 
    background: rgba($green, .1)
    transition: all .3s
    .icon
      fill: $green
    .icon-settings
      font-size: 18px
    .icon-notice
      font-size: 17px
    .icon-close
      display: none
      font-size: 12px
    &.active
      z-index: 15
      .icon-settings,
      .icon-notice
        display: none
      .icon-close
        display: inline-block
    &:hover
      background: rgba($green, .3)
  &__control.active &__counter
    display: none
  &__counter
    position: absolute
    top: 5px
    right: 5px
    display: flex
    justify-content: center
    align-items: center
    width: 10px
    height: 10px
    border-radius: 50%
    background: $red  
    +fb
    font-size: 6px
    line-height: 1
    color: $white 
  &__body
    text-align: center
    margin-top: -5px !important
  &__name
    margin-bottom: 5px
    +fb
    font-size: 14px
  &__mail
    margin-bottom: 18px
    font-size: 10px
  &__stat
    display: flex
    justify-content: center
  &__item
    margin: 0 14px
    +w
      margin: 0 7px
  &__number
    text-align: center
    +fm
    font-size: 21px
    +w
      font-size: 17px
  &__desc
    text-align: center
    font-size: 11px
    +w
      font-size: 10px
  &__font
    font-size: 9px
  &__wrap_tasks
    display: none
    position: absolute
    top: 0
    left: 0
    right: 0
    z-index: 14
    background: $white 
    padding: 35px 30px 30px 
  &__wrap_settings
    display: none
    position: absolute
    top: 0
    right: 0
    left: 0
    z-index: 12
    padding: 140px 70px 30px
    background: $white 
  &__category
    margin-bottom: 27px
    +fm
    font-size: 16px
    color: $black
  &__task
    padding: 15px 0
    border-top: 1px solid #E9E9E9  
  &__date
    margin-bottom: 2px
    font-size: 12px
    color: #C2C2C2 
  &__task &__text
    margin-bottom: 7px
    +fm
    font-size: 16px 
  &__indicators
    display: flex
    align-items: center 
  &__indicator
    font-size: 0
    &:not(:last-child)
      margin-right: 5px
  &__indicator &__pic
    width: 14px
  &__foot
    text-align: right
  &__more
    +fb
    font-size: 14px
    color: $green 
    transition: color .3s
    &:hover
      color: lighten($green, 10) 
  &__field
    &:not(:last-child)
      margin-bottom: 13px
  &__field:first-child .field__input
    +fm
    font-size: 14px
  &__btns
    padding-top: 12px
  &__bg
    display: none
    position: fixed
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 10
    background: rgba(#000, .05) 
    
.category
  &__item
    margin-top: -1px
    border: 1px solid $grey 
  &__item.active &__head
    padding: 24px 35px 21px 30px
    +w
      padding: 15px 25px 12px 15px 
    &:before
      transform: translateY(-45%) rotate(90deg)
  &__head
    position: relative
    padding: 18px 35px 15px 30px 
    font-size: 16px
    transition: all .3s 
    cursor: pointer
    +w
      padding: 15px 25px 12px 15px 
      font-size: 15px
    &:before
      content: ""
      position: absolute
      top: 50%
      right: 22px
      width: 7px
      height: 13px 
      transform: translateY(-45%)
      background: url('../img/arrow-right.svg') no-repeat 0 0 / 7px 13px 
      transition: all .3s 
      +w
        right: 15px
  &__item.active &__body
    display: block
  &__body
    display: none
    padding: 0 23px 0 27px 
    +w
      padding: 0 15px 
  &__line
    display: block
    padding: 12px 0 10px 3px
    font-size: 16px !important
    color: $black 
    width: 100%
    text-align: left
    +w
      padding: 9px 0 7px 3px
    &:first-child
      padding-top: 23px
      border-top: 1px solid $grey
      +w
        padding-top: 13px
    &:not(:last-child)
      border-bottom: 1px solid rgba($grey, .7)
    &_green
      color: $green

.scheduleq
  flex: 0 0 calc(100% - 332px)
  width: calc(100% - 332px)
  .scheduleq__links
    width: unset
    flex: unset
  .search-cell
    width: unset
    flex: 0 0 20%
  .stats-cell
    width: unset !important
    flex: unset !important
  +w
    flex: 0 0 calc(100% - 260px)
    width: calc(100% - 260px)
  &__title
    border-bottom: 1px solid #E9E9E9
    margin-bottom: 10px
    padding-left: 10px
    font-size: 15px
    display: flex
    justify-content: space-between
    &_action
      color: $green
  &__head
    height: 87px
    padding: 15px 55px 10px
    border-bottom: 1px solid rgba($black, .3) 
    +x
      padding: 15px 30px 10px
    +w
      height: 65px
      padding: 10px 20px 5px
  &__top
    display: flex
    align-items: center
    height: 100% 
    margin: 0 -10px
  &__cell
    flex: 0 0 calc(100%/3 - 20px)
    width: calc(100%/3 - 20px)
    margin: 0 10px 
    &_full
      width: unset
      flex-basis: unset
  &__container
    padding: 25px 55px 17px 
    +x
      padding: 25px 30px 17px
    +w
      padding: 20px 20px 13px 
  &__row
    display: flex
    margin: 0 -15px 
    +x
      margin: 0 -8px 
    &:not(:last-child)
      margin-bottom: 17px
  &__col
    margin: 0 15px 
    +x
      margin: 0 8px 
    &_border
      border-bottom: 1px solid $grey
      border-right: 1px solid $grey
      border-left: 1px solid $grey
      border-radius: 8px 8px 0px 0px
    &_w20
      flex: 0 0 calc(20% - 30px)
      width: calc(20% - 30px)
      +x
        flex: 0 0 calc(20% - 16px)
        width: calc(20% - 16px)
    &_w25
      flex: 0 0 calc(25% - 30px)
      width: calc(25% - 30px)
      +x
        flex: 0 0 calc(25% - 16px)
        width: calc(25% - 16px)
    &_w40
      flex: 0 0 calc(40% - 30px)
      width: calc(40% - 30px)
      +x
        flex: 0 0 calc(40% - 16px)
        width: calc(40% - 16px)
    &_w60
      flex: 0 0 calc(60% - 30px)
      width: calc(60% - 30px)
      +x
        flex: 0 0 calc(60% - 16px)
        width: calc(60% - 16px)
    &_w80
      flex: 0 0 calc(80% - 30px)
      width: calc(80% - 30px)
      +x
        flex: 0 0 calc(80% - 16px)
        width: calc(80% - 16px)
  &__wrap
    display: flex
    flex-wrap: wrap
    // margin: 0 -15px
    width: 100%
    +x
      margin: 0 -8px 
  &__information
    display: flex
    flex-wrap: wrap
    margin: 0 -22px
    +x
      margin: 0 -10px 15px    
  &__view
    display: flex
    &_link
      margin: 0 5px
      &-active
        color: #4AA343
        font-weight: bold

.search
  position: relative
  &__wrap
    position: relative
    // padding-right: 50px
    // +x
    //   padding-right: 25px
  &__input
    width: 100% 
    height: 30px
    padding: 0 0 4px 30px
    +f
    font-size: 16px
    color: $black
    +placeholder
      color: rgba(#606060, .3)
  &__btn
    position: absolute
    top: 0
    left: 0
    width: 20px
    height: 30px
    .icon
      font-size: 20px
      fill: rgba(#000, .6)
  &__tasks
    position: absolute
    width: 100%
    max-height: 400px
    overflow-y: auto
    z-index: 100
    background-color: white
    border: 1px solid $grey
    border-top: 0px
    padding: 5px 0px
.search-item
  position: relative
  cursor: pointer
  display: flex
  flex-direction: row
  height: 60px
  padding: 10px 30px
  border: 1px solid $grey
  overflow: hidden
  &__left-box
    width: 120px
    border-right: 1px solid $grey
    padding-right: 30px
  &__description
    padding-left: 30px
    &:after
      position: absolute
      bottom: 0
      height: 100%
      width: 100%
      content: ""
      background: linear-gradient(to top,rgba(255,255,255, 1) 00%, rgba(255,255,255, 0) 80%)
      pointer-events: none
  &__name
    margin-bottom: 0px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  &__date
    margin-bottom: 0px
    font-size: 12px
  .interval__variants
    margin-right: 0 !important

.interval
  display: flex
  justify-content: center
  align-items: center
  position: relative
  &__calendar
    margin-right: 20px
    &-icon
      width: 28px !important
      height: 28px !important
  &__variants
    margin: 0 20px 0 40px
    +fb
    font-size: 27px
    color: #606060 
    +x
      margin: 0 40px
      font-size: 23px 
    &_text-transform
      text-transform: lowercase
      &::first-letter
        text-transform: uppercase
  &__arrow
    font-size: 0
    .icon
      font-size: 31px
      fill: $green
      transition: all .3s
    &:hover .icon
      fill: lighten($green, 10)
    
 
.information
  position: relative
  cursor: pointer
  flex: 0 0 calc(100%/3 - 44px)
  width: calc(100%/3 - 44px)
  margin: 0 22px 20px
  padding: 18px 20px
  border-radius: 10px
  background: $green
  +x
    flex: 0 0 calc(100%/3 - 20px)
    width: calc(100%/3 - 20px)
    margin: 0 10px 10px
    padding: 13px 15px 
  &__date
    margin-bottom: 7px
    font-size: 7px
    color: $grey
  &__text
    +fb
    font-size: 18px
    line-height: math.div(23, 18) 
    color: $white 
    +x
      font-size: 16px
    &_done
      text-decoration: line-through
  &__remove
    position: absolute
    right: 10px
    top: 10px
    cursor: pointer
    svg
      fill: white

.field
  &__wrap  
    position: relative
    span
      font-size: 13px
      color: red
      position: absolute
      top: -18px
      left: 10px
  &__label
    font-size: 10px
    line-height: 1.1
    color: #D5D5D5 
  &__input
    width: 100%
    height: 28px
    padding: 0 5px 
    border-bottom: 1px solid #E9E9E9 
    +f
    font-size: 12px
    color: $black
    +placeholder
      color: $black
    &_wrapper
      position: relative
    &_icon
      position: absolute
      display: block
      cursor: pointer
      width: 25px
      height: 25px
      top: 11px
      right: 20px
      z-index: 100
  &_error
    border: 1px solid $red !important

.today
  display: flex
  flex-direction: column
  min-height: calc(100vh - 87px) 
  &__head
    display: flex
    align-items: center
    border-bottom: 1px solid rgba($black, .3) 
  &__col
    &:first-child
      flex: 0 0 660px
      width: 660px
      border-right: 1px solid rgba($black, .3) 
    &:nth-child(2)
      flex-grow: 1
      padding-right: 90px
  &__head &__date
    padding: 27px 55px 26px
    +fb
    font-size: 27px
    color: #606060 
  &__body
    display: flex
    flex-grow: 1
  &__links
    display: flex
    justify-content: flex-end
  &__link
    display: flex
    justify-content: center
    align-items: center
    width: 45px
    height: 45px 
    border-radius: 50% 
    background: rgba($green, .05)
    font-size: 0
    transition: all .3s
    .icon
      fill: $green
    .icon-link
      font-size: 19px
    .icon-cloud-arrow-down
      font-size: 18px
    .icon-delete
      font-size: 20px
    &:hover
      background: rgba($green, .3)
    &:not(:last-child)
      margin-right: 15px
  &__tasks
    margin-bottom: 40px
  &__item
    padding: 23px 170px 23px 55px
    border-bottom: 1px solid rgba($black, .3) 
    background: rgba($green, .05)
    font-size: 20px
    transition: all .3s
    &.active
      background: $green 
      color: $white 
  &__btns
    text-align: center
  &__btn
    min-width: 205px
    height: 68px
    border-radius: 34px 
    +fb
    font-size: 20px
  &__post
    padding: 40px 0 30px 65px
  &__title
    +fb
    font-size: 30px
    color: #000 
  &__top
    margin-bottom: 40px
  &__top &__date
    font-size: 14px
    color: #C2C2C2 
  &__text
    margin-bottom: 45px
    padding-right: 130px
    font-size: 21px
    line-height: math.div(35, 21) 
    p:not(:last-child)
      margin-bottom: 30px
  &__foot
    display: flex
    align-items: center
  &__element
    display: flex
    align-items: center
    margin-right: auto
  &__icon
    display: flex
    justify-content: center
    align-items: center
    flex: 0 0 80px
    width: 80px
    height: 80px
    margin-right: 30px
    border-radius: 13px
    background: #F9F9F9 
    font-size: 0
    .icon
      font-size: 48px
      fill: $green
  &__doc
    margin-bottom: 5px
    font-size: 20px
    color: $black 
  &__element &__date
    margin: 0
    font-size: 12px
    color: #000 
  &__control
    display: flex
  &__action
    font-size: 0
    .icon
      fill: rgba($black, .3)
      transition: fill .3s 
    .icon-delete
      font-size: 20px
    .icon-cloud-arrow-down
      font-size: 18px
    &:hover .icon
      fill: #000
    &:not(:last-child)
      margin-right: 13px

.popup
  position: fixed
  top: 0
  right: 0
  left: 0
  bottom: 0
  display: flex
  align-items: center
  min-width: 320px
  padding: 20px
  background-color: rgba(#000, .18)
  visibility: hidden
  opacity: 0
  pointer-events: none
  overflow: auto
  z-index: 999
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  transition: all .6s 
  &.visible
    pointer-events: all
    visibility: visible
    opacity: 1
    &::-webkit-scrollbar 
      display: none
  &__wrap
    position: relative
    max-width: 950px
    width: 100%
    margin: auto
    border-radius: 10px 
    background-color: $white
  &__close
    position: absolute
    top: 10px
    right: 10px
    font-size: 0
    .icon
      font-size: 20px
      fill: #000
      transition: fill .3s
    &:hover .icon
      fill: $black

.signin 
  margin: 60px auto 0px      
  .popup
    &__wrap
      position: relative
      max-width: 1100px
      width: 100%
      padding: 0
      margin: auto
      border-radius: 20px 
      opacity: 0
      transform: scale(.8)
      transition: opacity .5s, transform .5s
      background-color: $white
      +m
        max-width: 350px
    &__row
      display: flex
      align-items: center
      +t
        display: block
    &__box
      flex-grow: 1
      padding: 40px 75px
      +d
        padding: 25px 40px 
      +t
        padding: 35px 70px 
      +m
        padding: 25px 20px 
    &__preview
      flex: 0 0 600px
      width: 600px
      height: 600px 
      border-radius: 0 20px 20px 0 
      background-repeat: no-repeat
      background-position: 50% 50%
      background-size: cover 
      +d
        flex: 0 0 500px
        width: 500px
      +t
        flex: 0 0 100%
        width: 100%
        height: 450px
        border-radius: 0 0 20px 20px 
      +m
        display: none
    &__category
      margin-bottom: 15px
      text-align: center
      +fb
      font-size: 17px
      text-transform: uppercase
      line-height: 1
      color: #69C262 
      +r(330)
        font-size: 15px
    &__title
      margin-bottom: 75px
      text-align: center
      +fb
      font-size: 27px
      color: #1f1f1f 
      +d
        margin-bottom: 50px
      +t
        margin-bottom: 35px
      +m
        margin-bottom: 25px
        font-size: 22px
      br
        +m
          display: none
    &__error
      text-align: center
      color: $red
    &__fieldset
      margin-bottom: 23px
      +t
        margin-bottom: 15px
    &__line
      margin-bottom: 40px
      text-align: right
      font-size: 0
      +t
        margin-bottom: 30px
    &__field:not(:last-child)
      margin-bottom: 25px
    &__link
      display: inline-block
      font-size: 14px
      color: #D5D5D5
      transition: all .3s
      &:hover
        color: $black 
    &__btn
      min-width: 100%
      height: 50px 
      +fm
      font-size: 14px 
      line-height: 1
      &:not(:last-child)
        margin-bottom: 10px
    &__btn.btn_green
      background: #69C262
      color: white
      box-shadow: none
      &:hover
        background: lighten(#69C262, 5) 
    &__btn.btn_border-green
      border-width: 1px 
    &__icon
      display: flex
      justify-content: center
      align-items: center
      width: 85px
      height: 85px
      margin: 0 auto 
      border-radius: 50% 
      box-shadow: 0 0 0 25px rgba(#69C262, .2)
      background: #69C262 
      .icon
        font-size: 25px
        fill: $white
    &__close
      position: absolute
      top: -20px
      right: -20px
      font-size: 0
      +d
        top: -30px
        right: 0
      +t
        top: 15px
        right: 20px
      .icon
        font-size: 20px
        fill: $white
        transition: fill .3s 
        +t
          fill: $green 
      &:hover .icon
        fill: darken($white, 20)
    &_recovery &__title
      margin-bottom: 50px
      +m
        margin-bottom: 30px
    &_recovery &__fieldset
      margin-bottom: 70px
      +m
        margin-bottom: 40px
    &_complete &__title
      +t
        margin-bottom: 50px
    &_complete &__box
      padding: 30px 70px
      +t
        padding: 40px 70px 60px  
      +m
        padding: 40px 30px 50px 

  .field
    &__input
      width: 100%
      height: 50px
      padding: 0 25px 
      border-radius: 25px
      border: 1px solid #D5D5D5  
      +f
      font-size: 14px
      color: $black
      +placeholder
        color: #D5D5D5 


.verify
  .popup
    &__title
      margin-bottom: 5px
      text-align: center
      +fb
      font-size: 27px
      color: #1f1f1f 
      +d
        margin-bottom: 5px
      +t
        margin-bottom: 5px
      +m
        margin-bottom: 5px
        font-size: 22px
      br
        +m
          display: none
    &__description
      font-size: 18px
      color: $black
      margin-bottom: 70px    
      p
        width: 70%
        margin-left: 15%
        text-align: center
    &__btns
      text-align: center
    &__btn
      min-width: 60%
      border-radius: 30px

.forgot
  .popup
    &__btns
      margin-top: 30px

.icon-move
   height: 15px     
.timer
  width: 332px
  position: absolute
  bottom: 0
  left: 0
  background: #ffffff
  padding: 20px 0

.day-page
  display: flex
  width: 100%
  min-height: 100vh
  &__list
    width: 40%
    border-right: 1px solid #D5D5D5
  &__form
    width: 60%

.image-popup
  position: fixed
  top: 0
  right: 0
  display: none
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  padding: 20px
  background-color: rgba(0, 0, 0, 0.18)
  overflow: auto
  z-index: 1001
  &_visible
    display: flex
  img
    max-width: 100%
    max-height: 100%

.sidebar-popup
  position: absolute
  z-index: 100
  top: 50px
  left: 42%
  width: 220px
  max-height: 300px
  overflow: auto
  box-shadow: 0.5px 3px 6px 0px #949494
  border: 1px solid #E9E9E9
  border-bottom: none
  border-radius: 2px
  background: $white
  +w
    left: 30%
  &__item
    padding: 10px 18px
    cursor: pointer
    border-bottom: 1px solid #E9E9E9
    &:hover
      background: #F0F9EF
  &::-webkit-scrollbar  
    width: 4px 
    height: 3px 
    transition: all 0.5s
  &::-webkit-scrollbar-thumb  
    height: 50px 
    background-color: #A6A6A6 
    border-radius: 3px
  &::-webkit-scrollbar-corner  
    background-color: #999
  &::-webkit-resizer  
    background-color: #666
  &::-webkit-scrollbar-track  
    background-color: #EAEAEA

.header-calendar
  position: absolute
  z-index: 100000
  top: 120%
  left: 49%

.tags-list
  &__add
    color: $green
    padding-top: 16px !important
    p
      font-size: 16px !important
  &__item
    display: flex
    justify-content: space-between
    padding: 8px 0
    cursor: pointer
    border-top: 1px solid #E9E9E9
  &__name
    margin: 3px 0 3px 3px
  &__input
    border: 1px solid $green
    color: $black
    width: 100%
.w100
  width: 100% !important
.feedback-form
  margin-top: 25px
  &__title
    color: #606060
    margin-bottom: 10px
    font-size: 17px
    text-align: center
  &__textarea
    padding: 7px 10px
    width: 100%
    margin-bottom: 5px
    border-radius: 2px
  &__button
    width: 100%
    padding: 5px 7px
    background: #69C262
    color: white
    border: 0
    font-size: 15px
    border-radius: 2px
// .ant-btn-primary
//   background-color: #69C262 !important
//   border: 0 !important
.react-tabs__tab
  border-color: #1F1F1F !important
  outline: none !important
  box-shadow: none !important
  &::after
    all: unset !important
.align-self-start
  align-self: flex-start


