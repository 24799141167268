.note-form {
  &__title {
    font-size: 26px;
    font-family: 'plex medium';
    color: $gray;
    text-align: center;
    margin-bottom: 40px;
  }
  &__remind-text {
    margin-left: 10px;
    font-family: 'plex medium';
  }
  &__check-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__check-mark {
    cursor: pointer;

    path,
    circle {
      transition: stroke 0.2s;
    }
    &:hover {
      path,
      circle {
        stroke: $primary;
      }
    }

    &--done,
    &--done:hover {
      circle {
        fill: $primary;
        stroke: $primary;
      }
      path {
        stroke: white;
      }
    }
  }
}
