.goals {
  position: relative;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  &____item-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 8px;
  }
  &__item-due-date {
    margin-top: 3px;
    font-size: 11px;
    font-weight: 800;
    margin-right: 10px;
    text-align: right;
  }
  &__title {
    font-size: 20px;
    font-family: 'plex medium';
    color: $gray;
  }
  &__add {
    font-family: 'plex bold';
    text-transform: uppercase;
    font-size: 13px;
    color: $primary;
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: $primary-hover;
    }
  }

  &__add-item {
    margin-left: 8px;
    margin-right: 8px;
    width: calc(20% - 16px);
    min-width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-gray;
    border-radius: 8px;
    height: 60px;
    cursor: pointer;
    padding: 0 6px;
    transition: border-color 0.2s;
    &:hover {
      border-color: $gray;
      span {
        color: $gray;
      }
    }

    span {
      font-family: 'plex bold';
      text-transform: uppercase;
      font-size: 14px;
      color: $light-gray;
      transition: color 0.2s;
      text-align: center;
    }
  }

  &__scroll {
    overflow: auto;
    overflow-x: scroll;
    padding: 10px;
    // @include custom-scroll;
  }
  &__body {
    display: flex;
    width: 100%;
  }

  &__item {
    background-color: white;
    margin-left: 8px;
    margin-right: 8px;
    position: relative;
    // width: calc(25% - 16px);
    min-width: 200px;
    display: flex;
    // align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    border-radius: 8px;
    height: 60px;
    cursor: pointer;
    flex-direction: column;
    max-width: 220px;
    // transition: width 0.2s;
    &:hover {
      .goals__item-extra {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__items-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__item-title {
    display: flex;
    align-items: center;
    // width: 85%;

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__text {
      color: #69c262;
      font-family: 'plex medium';
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 18px;
      font-size: 15px;
    }
    &__monthly {
      font-size: 14px;
      color: $primary;
      font-family: 'plex medium';
    }
    &__emoji {
      margin-left: 5px;
      height: 22px;
      margin-right: 5px;
    }
  }
  &__item-extra {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -1px;
    right: -1px;
    border-top-right-radius: 14px;
    border-bottom-left-radius: 14px;
    padding: 4px;
    background-color: $primary;
    transition: 200ms;
  }
  &__item-extra-icon {
    path {
      fill: #ffffff !important;
    }
    height: 18px;
  }

  &__item-done {
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-top-left-radius: 14px;
    border-bottom-right-radius: 14px;
    transition: background-color 200ms;
    background-color: $primary;
    padding: 4px 8px;

    svg {
      margin-top: 3px;
      margin-left: 2px;
      margin-right: 1px;
      width: 14px;
      // height: 18px;
    }
    path {
      stroke-width: 2px;
      stroke: white;
      fill: white;
    }
  }
}

@media (min-width: $media-sm) {
  .goals__scroll {
    @include custom-scroll;
  }
}

@media (max-width: $media-sm) {
  .goals {
    &__scroll {
      margin-left: -6px;
      margin-right: -6px;
    }
    &__body {
      min-width: 140%;
    }
    &__item {
      min-width: 35% !important;
      margin-left: 6px !important;
      margin-right: 6px !important;
    }
    &__add-item {
      min-width: 20%;
      padding: 0;
      margin-left: 6px !important;
      margin-right: 6px !important;
    }
  }
}
