.lds-dual-ring 
  width: 35px
  height: 31px
  margin: auto

.lds-dual-ring:after 
  content: " "
  display: block
  width: 20px
  height: 20px
  margin: 8px
  border-radius: 50%
  border: 2px solid #69C262
  border-color: #69C262 transparent #69C262 transparent
  animation: lds-dual-ring 1.2s linear infinite

@keyframes lds-dual-ring 
  0% 
    transform: rotate(0deg)
  
  100% 
    transform: rotate(360deg)
