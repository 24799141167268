.settings-layout {
  &__body {
    padding: 68px 0px 0px 15%;
    background-color: #fafafa;
    // background-color: white;
    min-height: 100vh;
    position: relative;
  }
  &__wrapper {
    padding: 30px;
  }
}

.settings-sidebar {
  width: 15%;
  padding-top: 68px;
  z-index: 559;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  background-color: white;
  box-shadow: 6px 0px 30px 0px rgba(195, 199, 220, 0.26);

  &__body {
    margin-top: 35px;
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__title {
    font-size: 15px;
    font-family: 'plex bold';
    margin: 0 0 20px 30px;
    color: $light-gray;
  }

  &__item {
    padding: 10px 30px;
    list-style: none;
    font-family: 'plex medium';
    cursor: pointer;
    font-size: 18px;
    transition: color 0.2s;
    &:hover {
      color: $primary;
    }
    &--active {
      color: $primary;
    }
  }
}
@media (max-width: $media-sm) {
  .settings-layout {
    &__body {
      padding: 0 !important;
    }
    &__wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .settings-sidebar {
    width: 100%;
    padding-top: 0;
    position: static;
    box-shadow: none;
  }
}
