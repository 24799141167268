.react-select {
  width: 100%;
  font-size: 15px;

  &__control {
    border-radius: 6px !important;
    border-color: #ccd4d8 !important;
    transition: border-color 0.3s !important;
    &:hover {
      border-color: #808080 !important;
    }
    &--is-focused {
      border-color: $primary-active !important;
      box-shadow: none !important;
      &:hover {
        border-color: $primary-active !important;
      }
    }
  }

  &__placeholder {
    color: #99a8b1 !important;
  }
  &__indicator-separator {
    display: none;
  }
  &__menu {
    border-radius: 15px !important;
    overflow: hidden !important;
    box-shadow: 0px 10px 50px 0px rgba(#213554, 0.15);
  }
  &__menu-list {
    padding: 14px 0 !important;
    &::-webkit-scrollbar {
      width: 4px;
      &-thumb {
        border-radius: 6px;
        background-color: #a6a6a6;
      }
      &-track {
        background-color: #eaeaea;
      }
    }
  }
  &__option {
    padding: 12px 20px !important;
    cursor: pointer !important;
    color: #485a63 !important;
    &--is-focused {
      background-color: #ebeeef !important;
    }
    &--is-selected {
      background-color: lighten($primary, 30%) !important;
    }
  }

  &__multi-value {
    background-color: transparent !important;
    transition: background-color 0.2s;
    &:hover {
      background-color: #f4f4f4 !important;
    }
    &__label {
      font-size: 14px !important;
    }
    &__remove {
      cursor: pointer;
    }
  }
}
