@use "sass:math"

.stat
  // padding: 45px
  min-height: 250px !important
  &__row
    display: flex
  &__col:first-child
    flex-grow: 1
    padding-right: 45px
  &__col:nth-child(2)
    flex: 0 0 240px
    width: 240px
  &__head
    padding: 0 0 14px 10px
    border-bottom: 1px solid #E9E9E9 
    width: 100%
    +fb
    font-size: 24px
    line-height: math.div(31, 24) 
    color: #1f1f1f 
  &__date
    display: flex
    width: 100%
    justify-content: space-between
    margin: 20px 0 30px
    padding: 0 10px 5px 10px
    // border-bottom: 1px solid #E9E9E9
    +fm
    font-size: 16px
    color: #C2C2C2
  &__tags
    margin-bottom: 10px
    *
      cursor: pointer !important
  &__tag
    margin: 0 15px 15px 0 
    padding: 5px 9px 3px
    border: 1px solid $green
    border-radius: 4px   
    +fm
    font-size: 16px
    text-transform: uppercase
    color: $green 
  &__add
    margin-top: 10px
  &__text
    margin-bottom: 20px
    padding: 5px 0 15px 10px
    border: 1px solid #E9E9E9
    width: 100%
    min-height: 170px
    +fm
    font-size: 18px
    line-height: math.div(30, 18) 
    color: #707070 
    p:not(:last-child)
      margin-bottom: 20px
  &__info
    margin-bottom: 15px
    padding-left: 10px
    +fb
    font-size: 20px
    color: #1F1F1F 
  // &__field
  //   position: relative
  &__input
    width: 100%
    height: 54px
    padding: 0 70px 0 20px 
    border-radius: 10px 
    background: #F4F4F4
    +f
    font-size: 16px
    color: $black
    +placeholder
      color: #C2C2C2
  .edit-comment &__input
    font-size: 14px !important
    font-family: 'Segoe UI'
    padding-left: 10px
    margin-top: 16px
    height: 41px
    border-radius: 3px !important
    border: 1px solid #D5D5D5
  &__attach
    margin-left: 10px
    &_input
      display: none
    &:hover .icon
      fill: darken(#D5D5D5, 10)
    .icon
      font-size: 20px
      fill: #D5D5D5
      transition: fill .3s
  &__btn
    margin-top: 10px
    min-width: 100% 
    height: 47px
    border-radius: 24px 
    +fb
    font-size: 16px
    &:disabled
      cursor: default
      opacity: 0.9
  .tasks
    margin-bottom: 70px
    border: none 
    border-radius: 0 
  .tasks__body
    display: block
    padding: 22px 15px 20px 
    border-radius: 10px 
    background: #E9EEE9 
  .tasks__time
    +w
      display: inline
  &__checklist
    padding-left: 7px
    margin: 15px 0 30px
  &__input
    &_error
      border-bottom: 1px solid $red
  .timepicker-wrapper
    display: inline-block
    width: 100px
    
// .checklist
//   &__name 
//     margin-left: 4px
//     margin-bottom: -4px
//   &__list
//     margin-bottom: 10px
//   &__input
//     display: flex
//     align-items: center
//     width: 100%
//   &__item
//     display: flex
//     flex-wrap: nowrap
//     padding: 2px 5px
//     border-radius: 2px
//     &:hover
//       background: #f1f1f1
//   &__item_checked
//     font-style: italic
//     text-decoration: line-through    
//   &__actions
//     margin-left: auto 
//   &__add-item
//     // margin-top: 15px
//     height: 32px
//     display: flex
//     align-items: center
//     width: 100%
//   &__progress
//     margin-bottom: 0px !important  
//   &__checkbox
//     width: 100%  
//   &__add
//     cursor: pointer
//     display: flex
//     align-items: center
//     button
//       margin-left: 6px
//       font-weight: bold
//       font-size: 14.5px
    
.new-item
  &__actions
    margin-top: 10px
    display: flex
    flex-wrap: nowrap
    align-items: center
  &__btn
    &_close
      margin-left: 10px
    
.comments
  margin-top: 50px
  &__item
    margin: 0 0px 15px
    border-top: 1px solid #DCDCDC
  &__item-text
    font-size: 14.5px
    margin-bottom: 0 !important
    &__words
      display: flex
      flex-wrap: wrap
    & > span
      font-size: 12px
      font-weight: normal
    & span
      margin-right: 4px
  &__item-header
    display: flex
    justify-content: space-between
    align-items: center
    margin: 15px 0 0
    padding: 0px 10px

  &__item-delete
    svg
      opacity: 0.3
      transition: all .5s ease
      &:hover
        opacity: 0.8

.upload
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 10px
  z-index: 0
  border: 1px solid transparent
  transition: all .5s ease
  &:hover
    // filter: grayscale(.5)
    border: 1px solid $black
  &:hover &__delete
    opacity: 1
  &__name
    width: 40%
  &__mime
    width: 15%
    padding-left: 30px
  &__file
    display: flex
    justify-content: flex-end
    width: 45%
    img
      cursor: pointer
      max-width: 100%
      max-height: 200px
      min-width: 50px
      min-height: 50px
  &__delete
    position: absolute
    top: 10px
    right: 10px
    display: flex
    justify-content: flex-start
    align-items: flex-start
    padding: 5px
    opacity: 1
    z-index: 100
    transition: all .5s ease
    svg
      fill: #797979
    & > *
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      width: 30px
      height: 30px
      margin-right: 5px
      padding: 5px
      background-color: white
      border: 1px solid #797979
      filter: grayscale(1)
      transition: all .5s ease
      &:hover
        border-color: black
    // &__delete

.task-history
  padding-left: 10px

.task-action-history
  margin-top: 45px
  padding: 0 10px
  & > h1 
    font-family: 'Geometria-Bold'
    font-size: 20px
  &__item
    margin-bottom: 10px
    & > div
      & > *
        margin-right: 4px


.react-datepicker__time-list
  &::-webkit-scrollbar  
    width: 5px 
    height: 3px 
    transition: all 0.5s
  &::-webkit-scrollbar-thumb  
    height: 50px 
    background-color: #A6A6A6 
    border-radius: 3px
  &::-webkit-scrollbar-corner  
    background-color: #999
  &::-webkit-resizer  
    background-color: #666
  &::-webkit-scrollbar-track  
    background-color: #EAEAEA

.react-datepicker-wrapper
  border-bottom: 1px solid #E9E9E9

.picker-date
  width: 100px
  
.picker-time,
.timepicker-wrapper
  width: 50px !important


#daypageStat 
  &.stat
    padding-top: 30px !important

  .stat__date
    display: flex !important
    justify-content: space-between !important

  .title-row
    margin-bottom: 50px !important
    .stat__col:nth-child(2)
      flex: 0 0

