.sidebar {
  width: 15%;
  padding-top: 68px;
  z-index: 559;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  background-color: white;
  box-shadow: 6px 0px 30px 0px rgba(195, 199, 220, 0.26);
  transition: width 0.2s;
  &:hover {
    .sidebar__expander {
      opacity: 1;
    }
  }

  &--collapsed {
    width: 1.4%;
    // opacity: 0;
    .sidebar__body {
      opacity: 0;
      // display: none;
    }
  }

  &__body {
    overflow: auto;
    height: 100%;
    transition: opacity 0.2s;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__collapse {
    .ant-collapse-header {
      font-family: 'plex medium';
      color: $gray !important;
    }
    path {
      transition: 0.2s;
    }
    &.ant-collapse-item-active,
    &:hover {
      .ant-collapse-arrow {
        path {
          fill: $gray;
        }
      }
    }
  }

  .ant-collapse {
    margin-top: 30px;
  }
  .ant-collapse-header {
    font-size: 18px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .ant-collapse-arrow {
    margin-right: 18px;
    top: 23px !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  &__expander {
    z-index: 600;
    opacity: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 80px;
    right: -25px;
    cursor: pointer;
    transition: opacity 0.2s;
    &--visible {
      opacity: 1;
    }

    svg {
      transform: scale(0.9);
    }
    path {
      transition: fill 0.2s;
      fill: $primary;
    }
    &:hover {
      .sidebar__expander-wrapper {
        background-color: $primary;
      }
      path {
        fill: white;
      }
    }
  }
  &__expander-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }
}

.tags {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-height: 55vh;
  position: relative;

  &__color-borders {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    div {
      width: 9px;
      height: 100%;
    }
  }

  &__body {
    overflow: auto;
    @include custom-scroll;
    // position: relative;
    // min-height: 40px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;
    position: relative;
    transition: background-color 0.12s;
    &:hover {
      background-color: #ebeeef;
    }
  }
  &__item-color {
    position: absolute;
    width: 9px;
    height: 100%;
    left: 0;
    top: 0;
  }
  &__item-name {
    color: $gray;
    font-family: 'plex medium';
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__item-dots {
    cursor: pointer;
    color: red;
    margin-left: 10px !important;
    min-height: 18px;
    min-width: 18px;
    path {
      transition: fill 0.3s;
    }
    &:hover {
      path {
        fill: $gray;
      }
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 6px 0;
  }
  &__add-button {
    min-height: 34px;
    align-self: center;
    font-size: 12px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media (max-width: 1600px) {
  .sidebar {
    &:not(&--collapsed) {
      width: 17%;
    }
  }
}
@media (max-width: 1370px) {
  .sidebar {
    &:not(&--collapsed) {
      width: 19%;
    }
  }
}

@media (max-width: $media-sm) {
  .sidebar {
    width: 100% !important;
    height: 100%;
    position: static;
    padding-top: 0;
    padding-top: 10px;
    background-color: white;
    &__expander {
      display: none;
    }
    &__body {
      background-color: white;
      height: 100%;
    }
  }
  .tags {
    &__add-button {
      font-size: 13px;
    }
  }

  .ant-drawer-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .ant-drawer-body {
    padding: 0;
    background-color: white;
    height: 100%;
  }
  .ant-collapse {
    margin-top: 0px !important;
    background-color: white;
    height: 100%;
  }
}
